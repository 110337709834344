import { useMemo } from 'react';
import px from 'prop-types';
import { CreditCard } from 'Common/utils';

export default function CardNumber({
    separator = '-',
    lastFour = false,
    mask = 'X',
    issuer,
    number = Array(16).fill(mask).join(''),
}) {
    const value = useMemo(() => {
        const n = CreditCard.formatNumber(number, { separator, issuer });

        return lastFour ? n.slice(-4) : n;
    }, [separator, number, lastFour, issuer]);

    return value;
}

CardNumber.propTypes = {
    number: px.string,
    separator: px.string,
    lastFour: px.bool,
    mask: px.string,
    forceMask: px.bool,
    issuer: px.string,
};
