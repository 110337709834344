import 'Common/setup';
import { registerComponent } from 'Common/view';
import * as Components from './components';
import store from './store';
import * as controllers from './controllers';

function register(obj) {
    Object.keys(obj).forEach((key) => {
        if (obj[key].propTypes || typeof obj[key] === 'function') {
            registerComponent(key, obj[key], store);
        } else if (typeof obj[key] === 'object') {
            register(obj[key]);
        }
    });
}

Object.keys(controllers).forEach((key) => {
    window[key] = controllers[key];
});

register(Components);
