import { logger } from 'Common/core';
import { ReduxReducer } from 'Common/utils';

export const types = {
    ADD_ROUTE: 'HashRouter:routes:add',
    DEL_ROUTE: 'HashRouter:routes:del',
    UPDATE_ROUTE: 'HashRouter:routes:update',
    SET_ACTIVE: 'HashRouter:active:set',
    CLEAR_ACTIVE: 'HashRouter:active:clear',
};

export const initialState = {
    active: null,
    routes: [],
};

export const selectors = {
    getCurrentRouteId(state = {}) {
        return state.active ?? undefined;
    },
    getCurrentRoute(state = {}) {
        return (state.routes && state.active && state.routes.find((r) => r.id === state.active)) || undefined;
    },
    getRouteById(state = {}, id) {
        const route = state.routes?.find((r) => r.id === id) ?? undefined;

        return route && { ...route, active: route.id === state.active };
    },
    getAllRoutes(state = {}) {
        return state.routes?.map((r) => ({ ...r, active: state.active === r.id })) || [];
    },
    getRouteCount(state = {}) {
        return state.routes?.length ?? 0;
    },
};

export const actions = {
    /**
     * Add a route to the state
     * @param {string} id - route ID
     * @param {string?} label - display name for route
     * @param {object?} data - additional route data
     * @returns Action
     * @errors if no `id` provided or `id` already found in routes
     */
    addRoute(id, label, data) {
        return { type: types.ADD_ROUTE, payload: { id, label, data } };
    },
    /**
     * Updates data and label of route object at specified ID
     * @param {string} id
     * @param {object} data
     * @param {string} label
     * @returns Action
     * @errors if no `id` provided, `id` not found in routes, `data` is not an object and/or `label` is not a string
     */
    updateRoute(id, data, label) {
        return { type: types.UPDATE_ROUTE, payload: { id, data, label } };
    },
    /**
     * Updates data of route object at specified ID
     * @param {string} id
     * @param {object} data
     * @returns Action
     * @errors if no `id` provided, `id` not found in routes, or `data` is not an object
     */
    updateRouteData(id, data) {
        return { type: types.UPDATE_ROUTE, payload: { id, data } };
    },
    /**
     * Updates label of route object at specified ID
     * @param {string} id
     * @param {string} label
     * @returns Action
     * @errors if no `id` provided, `id` not found in routes, or `label` is not a string
     */
    updateRouteLabel(id, label) {
        return { type: types.UPDATE_ROUTE, payload: { id, label } };
    },
    /**
     * Remove a route from the state
     * @param {string} id - route ID
     * @returns Action
     * @errors if no `id` provded
     */
    removeRoute(id) {
        return { type: types.DEL_ROUTE, payload: { id } };
    },
    /**
     * Set route as active by route ID
     * @param {string} id - route ID
     * @returns Action
     * @errors if no `id` provided or `id` not found in routes
     */
    setActiveRoute(id) {
        return { type: types.SET_ACTIVE, payload: { id } };
    },
    /**
     * Clear any currently active route
     * @returns Action
     */
    clearActiveRoute() {
        return { type: types.CLEAR_ACTIVE };
    },
};

export const reducer = ReduxReducer.create(
    {
        [types.ADD_ROUTE](state, { id, label = '', data = {} } = {}) {
            if (!id) {
                logger.error(
                    'HashRouter ADD_ROUTE action requires payload { id: string, label: string?, data: object? }'
                );
            } else if (state.routes.find((r) => r.id === id)) {
                logger.error(`Duplicate route provided to HashRouter ADD_ROUTE action: ${id}`);
            } else {
                return {
                    ...state,
                    routes: state.routes.concat({ id, label, data }),
                };
            }
            return state;
        },

        [types.UPDATE_ROUTE](state, { id, label, data } = {}) {
            if (!id || (typeof label !== 'string' && typeof data !== 'object')) {
                logger.error(
                    'HashRouter UPDATE_ROUTE action requires payload { id: string, label: string } or { id: string, data: object }'
                );
            } else if (!state.routes.find((r) => r.id === id)) {
                logger.error(`Invalid ID provided to HashRouter UPDATE_ROUTE action: ${id}`);
            } else if (typeof label === 'string' && typeof data === 'object') {
                return {
                    ...state,
                    routes: state.routes.map((r) => (r.id === id ? { ...r, label, data: { ...r.data, ...data } } : r)),
                };
            } else if (typeof label === 'string') {
                return { ...state, routes: state.routes.map((r) => (r.id === id ? { ...r, label } : r)) };
            } else {
                return {
                    ...state,
                    routes: state.routes.map((r) => (r.id === id ? { ...r, data: { ...r.data, ...data } } : r)),
                };
            }
            return state;
        },

        [types.DEL_ROUTE](state, { id } = {}) {
            if (!id) {
                logger.error('HashRouter DEL_ROUTE action requires payload { id: string }');
            } else {
                return state.active === id || state.routes.find((r) => r.id === id)
                    ? {
                          ...state,
                          routes: state.routes.filter((r) => r.id !== id),
                          active: state.active === id ? null : state.active,
                      }
                    : state;
            }
            return state;
        },

        [types.SET_ACTIVE](state, { id } = {}) {
            if (!id) {
                logger.error('HashRouter SET_ACTIVE action requires payload { id: string }');
            } else if (!state.routes.find((r) => r.id === id)) {
                logger.error(`Invalid ID provided to HashRouter SET_ACTIVE action: ${id}`);
            } else {
                return { ...state, active: id };
            }
            return state;
        },

        [types.CLEAR_ACTIVE](state) {
            return state.active ? { ...state, active: null } : state;
        },
    },
    initialState
);
