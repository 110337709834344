import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import Icon from './Icon';

export default function Tag({ className, children, onClose, lblClose = 'remove', disabled, ...props }) {
    return (
        <div className={cx('Tag', className)} {...props}>
            <div className="Tag__content">{children}</div>
            {onClose ? (
                <button className="btn Tag__btn-close" onClick={onClose} disabled={disabled} aria-label={lblClose}>
                    <Icon name="times-circle" />
                </button>
            ) : null}
        </div>
    );
}

Tag.propTypes = {
    className: px.string,
    children: px.node,
    onClose: px.func,
    lblClose: px.string,
    disabled: px.bool,
};
