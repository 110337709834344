import { useMemo } from 'react';
import px from 'prop-types';
import { CreditCard } from 'Common/utils';

export default function CardCVV({
    mask = 'X',
    issuer = CreditCard.ISSUER.VISA,
    cvv = Array(issuer === CreditCard.ISSUER.AMEX ? 4 : 3)
        .fill(mask)
        .join(''),
}) {
    const value = useMemo(() => cvv.padStart(issuer === CreditCard.ISSUER.AMEX ? 4 : 3, mask), [cvv, issuer, mask]);

    return value;
}

CardCVV.propTypes = {
    mask: px.string,
    issuer: px.string,
    cvv: px.string,
};
