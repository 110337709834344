import { useEffect } from 'react';
import { useFieldApi } from 'informed';
import { PAYMENT } from 'Common/constants/fields';

export default function usePaymentToken(token, scope) {
    const tokenApi = useFieldApi(scope ? `${scope}.${PAYMENT.token}` : PAYMENT.token);

    useEffect(() => {
        if (token) tokenApi.setValue(token);
    }, [token, tokenApi]);
}
