import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { Translation } from 'Common/components/localization';
import { ProductBadge } from 'Common/components/product';
import { stopPropagation } from './helpers';

export default function LocationResult({ location, className, onGotoLocation, showCategories = false }) {
    const role = React.useMemo(() => (onGotoLocation ? 'button' : undefined), [onGotoLocation]);

    return (
        <li
            className={cx('LocationResult', className)}
            role={role}
            type={role}
            aria-label={location.name}
            onClick={onGotoLocation}
            tabIndex="0"
        >
            <div className="marker-icon">
                <i className="fas fa-map-marker-alt" />
            </div>
            <div className="details">
                {showCategories ? <ProductBadge badges={location.categories} className={'mb-2'} /> : null}
                <h4>{location.name}</h4>
                <p className="paragraph-2">
                    {location.address1}
                    <br />
                    {location.address2}
                    <br />
                    {location.phone}
                    <br />
                    {location.website ? (
                        <>
                            <a href={location.website} target="_blank" rel="noreferrer" onClick={stopPropagation}>
                                {location.website.replace(/\?.*$/, '')}
                            </a>
                            <br />
                        </>
                    ) : null}
                    <a target="_blank" rel="noreferrer" onClick={stopPropagation} href={location.directions}>
                        <i className="fas fa-map-marked-alt" aria-hidden="true" />{' '}
                        <Translation id="Dealer.Locator.MapDirections.Label" />
                    </a>
                </p>
            </div>
        </li>
    );
}

LocationResult.propTypes = {
    className: px.string,
    onGotoLocation: px.func,
    showCategories: px.bool,
    location: px.shape({
        id: px.oneOfType([px.string, px.number]),
        name: px.string,
        address1: px.string,
        address2: px.string,
        phone: px.string,
        website: px.string,
        directions: px.string,
        categories: px.arrayOf(px.string),
    }),
};
