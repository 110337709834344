import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import pick from 'lodash.pick';
import $ from 'jquery';
import { Form } from 'informed';
import * as types from 'Common/types';
import { useTranslation } from 'Common/hooks';
import { useExpDateFormat } from 'Common/hooks/payment';
import { Url, CreditCard, matchValue } from 'Common/utils';
import { HashRouter } from 'Common/components/app';
import { Icon, Loader, Modal } from 'Common/components/ui';
import { Translation } from 'Common/components/localization';
import { EMAIL, MONOFORM, SHIPMENT, ADDRESS, PAYMENT, EMPTY_PAYMENT, EMPTY_ADDRESS } from 'Common/constants/fields';
import * as GA4 from 'Common/constants/gtm';
import {
    CHECKOUT_PAGE_HASH_CUSTOMER,
    CHECKOUT_PAGE_HASH_SHIPPING,
    CHECKOUT_PAGE_HASH_PAYMENT,
    CART_LOCATION_HASH,
} from '~config/order';
import AddressVerificationDrawer from '../AddressVerificationDrawer';
import CheckoutSummary from './CheckoutSummary';
import CustomerInformation from './CustomerInformation';
import ShippingInformation, { GIFT_MESSAGE_MAX_LENGTH } from './ShippingInformation';
import PaymentInformation from './PaymentInformation';
import { AddressVerification } from 'Common/models';

const PAGE_ORDER = [CHECKOUT_PAGE_HASH_CUSTOMER, CHECKOUT_PAGE_HASH_SHIPPING, CHECKOUT_PAGE_HASH_PAYMENT];

const NO_VERIFY = {
    Address: null,
    VerifiedAddresses: [],
    onConfirm: null,
    onCancel: null,
};

function getCustomerUpdates(values, initialValues) {
    const { [MONOFORM.customer]: customerNext, [MONOFORM.shipments]: shipmentsNext } = values;
    const { [MONOFORM.customer]: customer, [MONOFORM.shipments]: shipments } = initialValues;

    return {
        customer: matchValue(customerNext, customer, { falseIsNull: true })
            ? false
            : { email: customerNext[EMAIL.email], signup: customerNext[EMAIL.signup] },
        shipments: shipments
            .map((s, i) => (matchValue(shipmentsNext[i], s, { falseIsNull: true }) ? null : shipmentsNext[i]))
            .filter(Boolean),
    };
}

function getShippingUpdates(values, initialValues) {
    const { [MONOFORM.shipments]: shipmentsNext } = values;
    const { [MONOFORM.shipments]: shipments } = initialValues;
    const mapped = shipmentsNext.map((s) =>
        s[SHIPMENT.giftMessage]
            ? { ...s, [SHIPMENT.giftMessage]: s[SHIPMENT.giftMessage].slice(0, GIFT_MESSAGE_MAX_LENGTH) }
            : s
    );

    return shipments
        .map((s, i) => (matchValue(mapped[i], s, { falseIsNull: true }) ? null : mapped[i]))
        .filter(Boolean);
}

function getPaymentUpdate(values, initialValues) {
    const { [MONOFORM.payment]: paymentNext } = values;
    const { [MONOFORM.payment]: payment } = initialValues;

    return matchValue(paymentNext, payment, { falseIsNull: true }) ? null : paymentNext;
}

function isSupportedPayment(paymentType) {
    switch (paymentType) {
        case 'ApplePay':
            return !!window.ApplePaySession;
        default:
            return true;
    }
}

export function NavItem({ id, label, active, data }) {
    return (
        <a className={cx('MultiPageCheckout__nav__link', { active })} href={`#${id}`} disabled={data?.disabled}>
            <Icon name="arrow-right-short" />
            {label}
        </a>
    );
}

NavItem.propTypes = {
    id: px.string,
    label: px.string,
    active: px.bool,
    data: px.shape({
        disabled: px.bool,
    }),
};

export function ConfirmModal({ onDiscard, onBack, onSave, open = false }) {
    return (
        <Modal
            className="CheckoutConfirmModal Modal--dark"
            showModal={open}
            onClose={onBack}
            footer={
                <div className="btn-list">
                    <button
                        className="btn btn-inverted"
                        type="button"
                        role="button"
                        data-dismiss="modal"
                        onClick={onBack}
                    >
                        <span>
                            <Translation id="Commerce.Order.Checkout.CancelSectionButton.Label" />
                        </span>
                    </button>
                    <button
                        className="btn btn-secondary"
                        type="button"
                        role="button"
                        data-dismiss="modal"
                        onClick={onDiscard}
                    >
                        <span>
                            <Translation id="Commerce.Order.Checkout.Changes.DiscardButton.Label" />
                        </span>
                    </button>
                    {onSave ? (
                        <button
                            className="btn btn-primary"
                            type="button"
                            role="button"
                            data-dismiss="modal"
                            onClick={onSave}
                        >
                            <span>
                                <Translation id="Commerce.Order.Checkout.Changes.SaveButton.Label" />
                            </span>
                        </button>
                    ) : null}
                </div>
            }
            noDefaultButtons
            noOptimization
        >
            <h6>
                <Translation id="Commerce.Order.Checkout.Changes.Title.Label" />
            </h6>
            <p>
                <Translation id="Commerce.Order.Checkout.Changes.Subtitle.Label" />
            </p>
        </Modal>
    );
}

ConfirmModal.propTypes = {
    onDiscard: px.func,
    onBack: px.func,
    onSave: px.func,
    open: px.bool,
};
export default function MultiPageCheckout({
    accountEmail,
    cartEmptyLabel,
    cartId,
    cartLoaded = false,
    cartLoading = false,
    className,
    currency,
    darkTheme,
    forms = [],
    getVerifiedAddresses,
    giftCardProps,
    gtmListValue,
    handleUpdates,
    homePage = '/',
    isLoggedIn = false,
    marketId,
    maxHashIndex,
    onPlaceOrder,
    orderEmail,
    promoProps,
    redirectWhenEmpty = homePage,
    savedShippingAddresses,
    shipments,
    signinLink,
    signoutLink,
    signupLink,
    siteId,
    summaryData,
    takeoverPage = false,
    totalQty = 0,
    ...props
}) {
    const lblBack = useTranslation('Block.OrderConfirmation.ContinueShipping.Button.Text');
    const takeoverPageRef = React.useRef(takeoverPage);
    const containerRef = React.useRef();
    const indexed = React.useRef(false);
    const [verification, setVerification] = React.useState(NO_VERIFY);
    const [confirmModal, setConfirmModal] = React.useState();
    const [requestError, setRequestError] = React.useState('');
    const submitted = React.useRef();
    const skipConfirm = React.useRef(false);
    const customerForm = React.useRef();
    const shippingForm = React.useRef();
    const paymentForm = React.useRef();
    const formatter = useExpDateFormat();
    const [giftcardError, setGiftcardError] = React.useState('');

    const paymentMethods = React.useMemo(
        () =>
            forms[0]?.AvailablePaymentMethods?.filter(
                (m) => m[PAYMENT.paymentType] !== 'GiftCard' && isSupportedPayment(m[PAYMENT.paymentType])
            ) ?? [],
        [forms]
    );

    const defaultMethod = React.useMemo(() => paymentMethods[0], [paymentMethods]);

    const payment = React.useMemo(
        () => forms[0]?.Payments?.find((p) => p[PAYMENT.paymentType] !== 'GiftCard'),
        [forms]
    );

    const defaultShipment = React.useMemo(() => {
        const physical = shipments?.filter((s) => !s[SHIPMENT.isDigital]);

        return physical?.length === 1 ? physical[0] : null;
    }, [shipments]);

    const verifyAddress = React.useCallback(
        async (address) => {
            const {
                success = false,
                addressVerification: { Address, VerifiedAddresses } = {},
                message = 'Commerce.Order.Cart.AddressVerification.FailureMessage',
            } = (await getVerifiedAddresses(address)) || {};

            if (success) {
                const verifiedUserInput = AddressVerification.verifyUserInput({ Address, VerifiedAddresses });

                if (verifiedUserInput != null) {
                    if (!verifiedUserInput.IsStreetAddress) {
                        throw new Error('Commerce.Order.Checkout.ShippingAddressVerification.StreetAddressWarning');
                    }

                    return { ...address, ...verifiedUserInput };
                }

                return new Promise((res, rej) => {
                    setVerification({
                        Address: Address,
                        VerifiedAddresses: VerifiedAddresses ?? [],
                        onConfirm: (selection) => {
                            setVerification(NO_VERIFY);
                            res({ ...address, ...selection });
                        },
                        onCancel: () => {
                            setVerification(NO_VERIFY);
                            rej();
                        },
                    });
                });
            }

            throw new Error(message);
        },
        [getVerifiedAddresses]
    );

    const initialValues = React.useMemo(() => {
        const pmt = payment
            ? CreditCard.paymentToForm({
                  payment,
                  method: paymentMethods.find((m) => m[PAYMENT.paymentType] === payment[PAYMENT.paymentType]),
                  formatter,
                  defaultShipment,
                  homogenize: true,
                  savePayment: !!paymentMethods
                      .find((p) => p[PAYMENT.paymentType] === payment[PAYMENT.paymentType])
                      ?.SavedPaymentInfo?.find((p) => p[PAYMENT.token] === payment[PAYMENT.token]),
              })
            : defaultMethod
            ? CreditCard.paymentToForm({
                  payment: EMPTY_PAYMENT,
                  method: defaultMethod,
                  homogenize: true,
                  defaultShipment,
                  formatter,
              })
            : {};

        return {
            [MONOFORM.shipments]: shipments?.map((s) => ({
                ...pick(s, Object.values(SHIPMENT)),
                [SHIPMENT.address]: s[SHIPMENT.address]
                    ? pick(s[SHIPMENT.address], Object.values(ADDRESS))
                    : EMPTY_ADDRESS,
            })),
            [MONOFORM.customer]: {
                [EMAIL.email]: orderEmail || accountEmail,
                [EMAIL.signup]: false,
            },
            [MONOFORM.payment]: pmt,
            // _billing: payment ? 'saved' : defaultShipment ? 'shipping' : 'custom',
        };
    }, [shipments, accountEmail, defaultShipment, orderEmail, payment, formatter, paymentMethods, defaultMethod]);

    const formsReady = React.useMemo(() => !!forms[0], [forms]);

    const initialPayment = React.useMemo(() => initialValues[MONOFORM.payment], [initialValues]);

    const recapData = React.useMemo(() => {
        let recap = null;

        if (maxHashIndex >= 1) {
            recap = {
                canEditContact: isLoggedIn,
                contact: orderEmail || accountEmail,
                shipments: shipments?.map((s) => ({
                    digital: s[SHIPMENT.isDigital],
                    gift: (maxHashIndex >= 2 && s[SHIPMENT.isGift] && s[SHIPMENT.giftMessage]) || '',
                    method:
                        maxHashIndex >= 2
                            ? [s[SHIPMENT.shippingMethodDisplay], s[SHIPMENT.shippingMethodInfo]].join(', ')
                            : '',
                    address: s[SHIPMENT.isDigital]
                        ? s[SHIPMENT.address][ADDRESS.email]
                        : [
                              s[SHIPMENT.address][ADDRESS.line1],
                              s[SHIPMENT.address][ADDRESS.city],
                              s[SHIPMENT.address][ADDRESS.state],
                          ].join(', '),
                })),
            };
        }
        return recap;
    }, [shipments, isLoggedIn, orderEmail, accountEmail, maxHashIndex]);

    const submitCustomer = React.useCallback(
        async ({ values }, next = CHECKOUT_PAGE_HASH_SHIPPING) => {
            const update = getCustomerUpdates(values, initialValues);
            const shipmentUpdates = [];

            try {
                setRequestError('');
                for (const s of update.shipments) {
                    const index = shipments?.findIndex(
                        ({ [SHIPMENT.shipmentId]: sId }) => sId === s[SHIPMENT.shipmentId]
                    );

                    if (s[SHIPMENT.isDigital]) {
                        shipmentUpdates.push({
                            ...s,
                            [SHIPMENT.address]: {
                                [ADDRESS.zip]: initialValues[MONOFORM.shipments][index][SHIPMENT.address][ADDRESS.zip],
                                ...s[SHIPMENT.address],
                            },
                        });
                    } else {
                        const address = await verifyAddress(s[SHIPMENT.address]);

                        if (!matchValue(s[SHIPMENT.address], address)) {
                            for (const k of Object.keys(s[SHIPMENT.address])) {
                                customerForm.current.setValue(
                                    `${MONOFORM.shipments}[${index}].${SHIPMENT.address}.${k}`,
                                    address[k]
                                );
                            }
                        }

                        if (!matchValue(address, initialValues[MONOFORM.shipments][index][SHIPMENT.address])) {
                            shipmentUpdates.push({
                                ...s,
                                [SHIPMENT.address]: address,
                            });
                        }
                    }
                }

                if ((update.customer || shipmentUpdates.length) && handleUpdates) {
                    await handleUpdates(
                        [
                            update.customer ? { type: GA4.CHECKOUT_STEPS.CUSTOMER, data: update.customer } : null,
                            ...shipmentUpdates.map(
                                ({
                                    [SHIPMENT.shipmentId]: shipmentId,
                                    [SHIPMENT.address]: { [ADDRESS.defaultAddress]: useDefault, ...address },
                                }) => ({ type: 'address', data: { shipmentId, address, useDefault } })
                            ),
                        ].filter(Boolean)
                    );
                }

                if (PAGE_ORDER.includes(next)) {
                    skipConfirm.current = true;
                    Url.current.setHash(next).apply(true);
                }
            } catch (e) {
                setRequestError(Array.isArray(e) ? e[0]?.message ?? e[0] : e?.message ?? e);
            }
        },
        [handleUpdates, initialValues, shipments, verifyAddress]
    );

    const submitShipping = React.useCallback(
        async ({ values }, next = CHECKOUT_PAGE_HASH_PAYMENT) => {
            const updates = getShippingUpdates(values, initialValues);

            try {
                setRequestError('');
                if (updates.length && handleUpdates) {
                    await handleUpdates(updates.map((s) => ({ type: GA4.CHECKOUT_STEPS.SHIPPING, data: s })));
                }

                if (PAGE_ORDER.includes(next)) Url.current.setHash(next).apply(true);
            } catch (e) {
                setRequestError(Array.isArray(e) ? e[0]?.message ?? e[0] : e?.message ?? e);
            }
        },
        [handleUpdates, initialValues]
    );

    const submitGiftcard = React.useCallback(
        async (data) => {
            try {
                await handleUpdates([{ type: 'giftcard', data }]);
            } catch (e) {
                setGiftcardError(Array.isArray(e) ? e[0]?.message ?? e[0] : e?.message ?? e);
                setTimeout(() => {
                    setGiftcardError('');
                }, 5000);
            }
        },
        [handleUpdates]
    );

    const submitPayment = React.useCallback(
        async ({ values }, next) => {
            const update = getPaymentUpdate(values, initialValues);

            try {
                setRequestError('');
                if (update) {
                    if (CreditCard.isValidPayment(update)) {
                        await handleUpdates([{ type: GA4.CHECKOUT_STEPS.PAYMENT, data: update }]);
                    } else {
                        throw new Error('Commerce.Order.Checkout.MissingPayment.Error');
                    }
                }

                skipConfirm.current = true;
                if (PAGE_ORDER.includes(next)) Url.current.setHash(next).apply(true);
                else {
                    submitted.current = true;
                    await onPlaceOrder();
                }
            } catch (e) {
                setRequestError(Array.isArray(e) ? e[0]?.message ?? e[0] : e?.message ?? e);
            }
        },
        [handleUpdates, onPlaceOrder, initialValues]
    );

    const leaveCheckout = React.useCallback(() => {
        if (redirectWhenEmpty) window.location.href = Url.create(redirectWhenEmpty).setHash(CART_LOCATION_HASH).href;
    }, [redirectWhenEmpty]);

    const gotoHash = React.useCallback(
        (hash) => () => {
            Url.current.setHash(hash).apply(true);
        },
        []
    );

    const clearRequestError = React.useCallback(() => setRequestError(''), []);

    const onCancelNavigation = React.useCallback(() => {
        setConfirmModal(null);
    }, []);

    const onDiscardChanges = React.useCallback(() => {
        if (confirmModal) {
            const api =
                confirmModal.from === CHECKOUT_PAGE_HASH_CUSTOMER
                    ? customerForm
                    : confirmModal.from === CHECKOUT_PAGE_HASH_SHIPPING
                    ? shippingForm
                    : paymentForm;

            if (api.current) {
                api.current.reset();
            }
            setConfirmModal(null);
            Url.current.setHash(confirmModal.to).apply(true);
        }
    }, [confirmModal]);

    const onConfirmChanges = React.useMemo(
        () =>
            confirmModal?.isValid
                ? async () => {
                      if (confirmModal) {
                          setConfirmModal(null);
                          if (confirmModal.from === CHECKOUT_PAGE_HASH_CUSTOMER) {
                              submitCustomer(confirmModal, confirmModal.to);
                          } else if (confirmModal.from === CHECKOUT_PAGE_HASH_SHIPPING) {
                              submitShipping(confirmModal, confirmModal.to);
                          } else {
                              submitPayment(confirmModal, confirmModal.to);
                          }
                      }
                  }
                : null,
        [confirmModal, submitPayment, submitCustomer, submitShipping]
    );

    const onHashChange = React.useCallback(
        (next, prev) => {
            const page =
                !PAGE_ORDER.includes(next) || PAGE_ORDER.indexOf(next) > maxHashIndex ? PAGE_ORDER[maxHashIndex] : next;

            if (page === prev) return true;

            if (!skipConfirm.current) {
                if (prev === CHECKOUT_PAGE_HASH_CUSTOMER) {
                    const { values } = customerForm.current?.getFormState() || {};
                    const update = values ? getCustomerUpdates(values, initialValues) : {};

                    if (update.customer || update.shipments?.length) {
                        setConfirmModal({ from: CHECKOUT_PAGE_HASH_CUSTOMER, to: page, values, isValid: true });
                        return true;
                    }
                } else if (prev === CHECKOUT_PAGE_HASH_SHIPPING) {
                    const { values } = shippingForm.current?.getFormState() || {};
                    const updates = values && getShippingUpdates(values, initialValues);

                    if (updates?.length) {
                        setConfirmModal({ from: CHECKOUT_PAGE_HASH_SHIPPING, to: page, values, isValid: true });
                        return true;
                    }
                } else if (prev === CHECKOUT_PAGE_HASH_PAYMENT) {
                    const { values } = paymentForm.current?.getFormState() || {};
                    const update = values && getPaymentUpdate(values, initialValues);

                    if (update) {
                        setConfirmModal({
                            from: CHECKOUT_PAGE_HASH_PAYMENT,
                            to: page,
                            values,
                            isValid: CreditCard.isValidPayment(update),
                        });
                        return true;
                    }
                }
            }

            skipConfirm.current = false;
            setRequestError('');
            return false;
        },
        [maxHashIndex, initialValues]
    );

    React.useEffect(() => {
        if (takeoverPageRef.current && containerRef.current) {
            $('html, body').css('background-color', window.getComputedStyle(containerRef.current).backgroundColor);
            $('body > *:not(.content__page)').addClass('__overtaken__');
            $('.content__page').css('margin-bottom', '0px');
            return () => {
                $('html, body').css('background-color', '');
                $('.__overtaken__').removeClass('__overtaken__');
                $('.content__page').css('margin-bottom', '');
            };
        }
    }, []);

    React.useEffect(() => {
        if (shipments?.length && shipments.find((s) => s.LineItems?.length)) {
            if (!indexed.current) {
                const page = PAGE_ORDER[maxHashIndex];

                indexed.current = true;
                Url.current.setHash(page).apply();
            }
        } else if (cartLoaded && !submitted.current) {
            leaveCheckout();
        }
    }, [shipments, leaveCheckout, orderEmail, accountEmail, cartLoaded, maxHashIndex]);

    return (
        <div className={cx('MultiPageCheckout', className, darkTheme)} ref={containerRef} {...props}>
            {cartLoading ? <Loader /> : null}
            <ConfirmModal
                open={!!confirmModal}
                onBack={onCancelNavigation}
                onDiscard={onDiscardChanges}
                onSave={onConfirmChanges}
            />
            <AddressVerificationDrawer
                open={!!verification.Address}
                onSelect={verification.onConfirm}
                onCancel={verification.onCancel}
                address={verification.Address}
                verifiedAddresses={verification.VerifiedAddresses}
                backdrop
            />
            <div className="MultiPageCheckout__mobile-header d-flex d-lg-none">
                <a href={homePage} aria-label={lblBack}>
                    <Icon sheet="brand" name="logo" className="color-primary" />
                </a>
            </div>
            <HashRouter.Router
                useJqueryScroll={{ topOnInitialLoad: true, offset: -32, speed: 350 }}
                onChange={onHashChange}
            >
                <div className="MultiPageCheckout__col col-left">
                    <div className="MultiPageCheckout__header d-none d-lg-block">
                        <a href={homePage} aria-label={lblBack}>
                            <Icon sheet="brand" name="logo" className="color-primary" />
                        </a>
                    </div>
                    <div className="MultiPageCheckout__nav d-none d-lg-block">
                        <HashRouter.Nav NavItem={NavItem} />
                    </div>
                    <div className="MultiPageCheckout__content">
                        <HashRouter.Route
                            className="MultiPageCheckout__content__page"
                            id={CHECKOUT_PAGE_HASH_CUSTOMER}
                            label="Customer Information"
                            noDomId
                            removeFromDomWhenInactive
                        >
                            {formsReady ? (
                                <Form
                                    initialValues={initialValues}
                                    onSubmit={submitCustomer}
                                    formApiRef={customerForm}
                                    validateOnMount
                                >
                                    <CustomerInformation
                                        onBack={leaveCheckout}
                                        disabled={cartLoading}
                                        className="MultiPageCheckout__CustomerInformation"
                                        shipments={shipments}
                                        isLoggedIn={isLoggedIn}
                                        siteId={siteId}
                                        signinLink={signinLink}
                                        signupLink={signupLink}
                                        signoutLink={signoutLink}
                                        savedAddresses={savedShippingAddresses}
                                        requestError={requestError}
                                    />
                                </Form>
                            ) : null}
                        </HashRouter.Route>
                        <HashRouter.Route
                            className="MultiPageCheckout__content__page"
                            id={CHECKOUT_PAGE_HASH_SHIPPING}
                            label="Shipping"
                            data={{ disabled: !maxHashIndex }}
                            noDomId
                            removeFromDomWhenInactive
                        >
                            {formsReady ? (
                                <Form
                                    initialValues={initialValues}
                                    onSubmit={submitShipping}
                                    formApiRef={shippingForm}
                                    validateOnMount
                                >
                                    <ShippingInformation
                                        onBack={gotoHash(CHECKOUT_PAGE_HASH_CUSTOMER)}
                                        disabled={cartLoading}
                                        className="MultiPageCheckout__ShippingInformation"
                                        shipments={shipments}
                                        orderEmail={orderEmail}
                                        currency={currency}
                                        isLoggedIn={isLoggedIn}
                                        requestError={requestError}
                                    />
                                </Form>
                            ) : null}
                        </HashRouter.Route>
                        <HashRouter.Route
                            className="MultiPageCheckout__content__page"
                            id={CHECKOUT_PAGE_HASH_PAYMENT}
                            label="Payment"
                            data={{ disabled: maxHashIndex < 2 }}
                            noDomId
                            removeFromDomWhenInactive
                        >
                            {formsReady ? (
                                <Form
                                    initialValues={initialValues}
                                    onSubmit={submitPayment}
                                    formApiRef={paymentForm}
                                    validateOnMount
                                >
                                    <PaymentInformation
                                        onBackToCustomer={gotoHash(CHECKOUT_PAGE_HASH_CUSTOMER)}
                                        onBack={gotoHash(CHECKOUT_PAGE_HASH_SHIPPING)}
                                        disabled={cartLoading}
                                        className="MultiPageCheckout__PaymentInformation"
                                        shipments={shipments}
                                        orderEmail={orderEmail}
                                        currency={currency}
                                        paymentMethods={paymentMethods}
                                        marketId={marketId}
                                        siteId={siteId}
                                        initialPayment={initialPayment}
                                        isLoggedIn={isLoggedIn}
                                        requestError={requestError}
                                        clearRequestError={clearRequestError}
                                        defaultShipment={defaultShipment}
                                        paymentAmount={forms[0]?.RequiredPaymentAmount}
                                    />
                                </Form>
                            ) : null}
                        </HashRouter.Route>
                    </div>
                </div>
                <div className="MultiPageCheckout__col col-right">
                    <CheckoutSummary
                        className={cx('MultiPageCheckout__summary', darkTheme)}
                        forms={forms}
                        summaryData={summaryData}
                        emptyLabel={cartEmptyLabel}
                        loaded={cartLoaded}
                        currency={currency}
                        gtmListValue={gtmListValue}
                        loading={cartLoading}
                        promoProps={promoProps}
                        giftCardProps={
                            giftCardProps
                                ? { ...giftCardProps, onUpdate: submitGiftcard, errorMessage: giftcardError }
                                : null
                        }
                        onBackToCustomer={gotoHash(CHECKOUT_PAGE_HASH_CUSTOMER)}
                        onBackToShipping={gotoHash(CHECKOUT_PAGE_HASH_SHIPPING)}
                        recapData={recapData}
                        cartId={cartId}
                        totalQty={totalQty}
                    />
                </div>
            </HashRouter.Router>
        </div>
    );
}

MultiPageCheckout.propTypes = {
    accountEmail: px.string,
    cartEmptyLabel: px.string,
    cartId: px.node,
    cartLoaded: px.bool,
    cartLoading: px.bool,
    className: px.string,
    currency: px.string,
    darkTheme: px.string,
    forms: px.arrayOf(types.CartForm),
    getVerifiedAddresses: px.func,
    giftCardProps: px.objectOf(px.any),
    gtmListValue: px.string,
    handleUpdates: px.func,
    homePage: px.string,
    isLoggedIn: px.bool,
    marketId: px.string,
    maxHashIndex: px.number,
    onPlaceOrder: px.func,
    orderEmail: px.string,
    promoProps: px.objectOf(px.any),
    redirectWhenEmpty: px.string,
    savedShippingAddresses: px.arrayOf(types.Address),
    shipments: px.arrayOf(types.Shipment),
    signinLink: px.string,
    signoutLink: px.string,
    signupLink: px.string,
    siteId: px.string,
    summaryData: types.OrderSummary,
    takeoverPage: px.bool,
    totalQty: px.number,
};
