import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import * as types from 'Common/types';
import { useAction } from 'Common/hooks';
import { Badge } from 'Common/components/ui';
import { order } from '~features';

export default function CartBadge({ horizontal = 'left', vertical = 'top', ...props }) {
    const loadedRef = React.useRef(false);
    const hasCart = useSelector(order.selectors.hasCart);
    const isUpdating = useSelector(order.selectors.isCartUpdating);
    const qty = useSelector(order.selectors.getTotalQuantity);
    const loadCart = useAction(order.actions.loadCart);

    React.useEffect(() => {
        if (!hasCart && !isUpdating && !loadedRef.current) {
            loadedRef.current = true;
            loadCart();
        }
    }, [isUpdating, hasCart, loadCart]);

    return hasCart ? (
        <Badge horizontal={horizontal} vertical={vertical} {...props}>
            {qty}
        </Badge>
    ) : null;
}

CartBadge.propTypes = {
    horizontal: px.string,
    vertical: px.string,
};
