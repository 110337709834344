import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { Checkbox, useFormApi, Radio, RadioGroup } from 'informed';
import * as types from 'Common/types';
import { useTranslator } from 'Common/hooks';
import { SHIPMENT, MONOFORM } from 'Common/constants/fields';
import { Translation, Currency } from 'Common/components/localization';
import { Field, CharCounter } from 'Common/components/forms';
import { Loader } from 'Common/components/ui';
// import Recap from './Recap';

export const GIFT_MESSAGE_MAX_LENGTH = 50;

function disabledCondition(index) {
    return ({ formApi }) => ({ disabled: !formApi.getValue(`${MONOFORM.shipments}[${index}].${SHIPMENT.isGift}`) });
}

function onValidateGiftMessage(value) {
    if (value?.length > GIFT_MESSAGE_MAX_LENGTH) {
        return 'Commerce.Order.Checkout.GiftMessageMaxLengthLabel';
    }
    return undefined;
}

export default function ShippingInformation({
    className,
    disabled,
    // orderEmail,
    shipments = [],
    currency,
    // isLoggedIn = false,
    requestError,
    onBack,
}) {
    const formApi = useFormApi();
    const lblShipment = useTranslator('Commerce.Order.Checkout.Shipment.Split.Title');

    const chooseMethod = React.useCallback(
        (index, method) => {
            formApi.setValue(`${MONOFORM.shipments}[${index}].${SHIPMENT.shippingMethod}`, method);
        },
        [formApi]
    );

    const clearGiftMessage = React.useCallback(
        (index) => () => {
            formApi.clearValue(`${MONOFORM.shipments}[${index}].${SHIPMENT.giftMessage}`);
        },
        [formApi]
    );

    return (
        <div className={cx('ShippingInformation', className)}>
            {/* <Recap shipments={shipments} orderEmail={orderEmail} onBackToCustomer={onBack} isLoggedIn={isLoggedIn} /> */}

            {shipments?.map((shipment, index) => (
                <section
                    className="checkout-section"
                    key={`checkout-shipping-methods-${shipment[SHIPMENT.shipmentId]}`}
                >
                    <div className="checkout-section__title">
                        {shipments?.length > 1 ? (
                            lblShipment({ index: index + 1 })
                        ) : (
                            <Translation id="Commerce.Order.Detail.ShippingMethod.Label" />
                        )}
                    </div>
                    <fieldset className="checkout-section__fieldset" disabled={disabled}>
                        <Field
                            hidden
                            required
                            name={`${MONOFORM.shipments}[${index}].${SHIPMENT.shipmentId}`}
                            value={shipment[SHIPMENT.shipmentId]}
                        />
                        <Field
                            hidden
                            name={`${MONOFORM.shipments}[${index}].${SHIPMENT.isDigital}`}
                            value={shipment[SHIPMENT.isDigital]}
                        />
                        <Field
                            hidden
                            required
                            name={`${MONOFORM.shipments}[${index}].${SHIPMENT.address}`}
                            value={shipment[SHIPMENT.address]}
                        />
                        {shipment.AvailableShippingMethods?.length ? (
                            <Field
                                Component={RadioGroup}
                                name={`${MONOFORM.shipments}[${index}].${SHIPMENT.shippingMethod}`}
                                required
                            >
                                <div className="checkout-grid">
                                    {shipment.AvailableShippingMethods?.map((method) => (
                                        <button
                                            role="button"
                                            type="button"
                                            onClick={() => chooseMethod(index, method.ShippingMethodName)}
                                            key={`shipping-${shipment[SHIPMENT.shipmentId]}-${
                                                method.ShippingMethodName
                                            }`}
                                        >
                                            <div className="heading">
                                                <div className="radio d-flex">
                                                    <Radio value={method.ShippingMethodName} />
                                                </div>
                                                <div>
                                                    <div>{method.DisplayName}</div>
                                                    <div>
                                                        <strong>{method.DeliveryInfo}</strong>
                                                    </div>
                                                </div>
                                                <div>
                                                    <strong>
                                                        {method.EstimatedCost ? (
                                                            <Currency
                                                                amount={method.EstimatedCost}
                                                                currency={currency}
                                                            />
                                                        ) : (
                                                            <Translation id="Commerce.Order.Cost.Free.Label" />
                                                        )}
                                                    </strong>
                                                </div>
                                            </div>
                                        </button>
                                    ))}
                                </div>
                            </Field>
                        ) : (
                            <Loader />
                        )}
                    </fieldset>
                    <fieldset className="checkout-section__fieldset row">
                        <Field
                            className="col-12"
                            Component={Checkbox}
                            name={`${MONOFORM.shipments}[${index}].${SHIPMENT.isGift}`}
                            label={<Translation id="Commerce.Order.Checkout.GiftOption.Label" />}
                            labelInline
                            labelLast
                            hideError
                            onChange={clearGiftMessage(index)}
                        />
                        <Field
                            className="col-12"
                            name={`${MONOFORM.shipments}[${index}].${SHIPMENT.giftMessage}`}
                            placeholder="Commerce.Order.Checkout.GiftMessage.Label"
                            disabledConditionEval={disabledCondition(index)}
                            disabledConditionWhen={[`${MONOFORM.shipments}[${index}].${SHIPMENT.isGift}`]}
                            validate={onValidateGiftMessage}
                            inlineChildren
                        >
                            <CharCounter
                                max={GIFT_MESSAGE_MAX_LENGTH}
                                field={`${MONOFORM.shipments}[${index}].${SHIPMENT.giftMessage}`}
                            />
                        </Field>
                    </fieldset>
                </section>
            ))}

            {requestError ? (
                <div className="alert alert-danger my-4">
                    <i className="fas fa-exclamation-triangle text-danger mr-2" />
                    <Translation id={requestError} />
                </div>
            ) : null}

            <div className="checkout-buttons">
                <button type="submit" role="submit" className="btn btn-lg btn-primary" disabled={!shipments?.length}>
                    <span>
                        <Translation id="Commerce.Order.Checkout.Buttons.ContinueToPayment.Label" />
                    </span>
                </button>
                <button type="button" role="button" className="btn btn-lg btn-inverted" onClick={onBack}>
                    <span>
                        <Translation id="Commerce.Order.Checkout.Buttons.PreviousSection.Label" />
                    </span>
                </button>
            </div>
        </div>
    );
}

ShippingInformation.propTypes = {
    className: px.string,
    disabled: px.bool,
    shipments: px.arrayOf(types.Shipment),
    // orderEmail: px.string,
    onBack: px.func,
    currency: px.string,
    // isLoggedIn: px.bool,
    requestError: px.string,
};
