/* eslint-disable react/prop-types */
import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { Location, Url } from 'Common/utils';
import { useTranslation, useScript } from 'Common/hooks';
import { Spinner } from 'Common/components/ui';
import { logger } from 'Common/core';

const LocationSearch = React.forwardRef(function LocationSearchComponent(
    {
        className,
        options = {},
        type = 'text',
        placeholder,
        name,
        apiKey,
        region = 'us',
        storeLocatorPageUrl,
        ...rest
    },
    inputRef
) {
    const autoComplete = React.useRef(null);
    const optionsRef = React.useRef(options);
    const defaultInputRef = React.useRef();
    const inputRefRef = React.useRef(inputRef ?? defaultInputRef);
    const locationTip = useTranslation('Dealer.Locator.Search.CurrentLocation.ToolTip');
    const clearTip = useTranslation('Dealer.Locator.Search.ClearInput.ToolTip');
    const [error, setError] = React.useState(null);

    const [value, setValue] = React.useState(null);
    const submitLbl = useTranslation('Footer.Store.Locator.SubmitButton.Label');

    const onSubmit = React.useCallback(
        (location) => {
            const url = new Url(storeLocatorPageUrl);

            if (location?.lat) {
                url.addParam('lat', location.lat)
                    .addParam('lng', location.lng)
                    .addParam('addr', location.fullAddress ?? location.address);
            }
            window.location = url.href;
        },
        [storeLocatorPageUrl]
    );

    const onPlaceChange = React.useCallback(
        (loc) => {
            if (loc) {
                setValue(loc.fullAddress);
                onSubmit(loc);
            } else {
                setValue('');
            }
        },
        [onSubmit]
    );

    const onClearInput = React.useCallback(() => setValue(''), []);

    const google = useScript(
        apiKey ? `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&region=${region}` : null,
        {
            onLoad: async (lib) => {
                const maps = await lib.maps.importLibrary('maps');

                lib.maps = { ...lib.maps, ...maps };
                return lib;
            },
            globalNamespace: 'google',
            delay: 2000,
            destroyOnDismount: false,
        }
    );

    const onChange = React.useCallback(() => {
        const nextPlace = autoComplete.current.getPlace();

        if (nextPlace?.geometry && onPlaceChange) {
            onPlaceChange(Location.addressFromPlace(nextPlace));
        }
    }, [onPlaceChange]);

    const onChangeRef = React.useRef(onChange);

    const onChangeSearch = React.useCallback((e) => {
        setValue(e.target.value);
    }, [setValue])


    const requestLocation = React.useCallback(async () => {
        if (window.navigator?.geolocation) {
            setError(false);
                
            window.navigator.geolocation.getCurrentPosition(
                async (pos) => {
                    const { latitude, longitude } = pos.coords;

                    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

                    try {
                        const response = await fetch(url);

                        if (!response.ok) {
                            setError("Could not find your location")
                        }
                        const data = await response.json();
                    
                        const currentLocation = data?.plus_code?.compound_code?.split(' ').slice(1, -1).join(' ').slice(0,-1);
                        
                        setValue(currentLocation);
                    } catch (err) {
                        logger.warn('Location services failed', err);
                    }

                },
            );
        };
    }, [setValue, apiKey]);

    React.useEffect(() => {
        onChangeRef.current = onChange;
    }, [onChange]);

    React.useEffect(() => {});

    React.useEffect(() => {
        function handler(e) {
            return onChangeRef.current(e);
        }

        if (google && !autoComplete.current) {
            const el = inputRefRef.current.current;

            autoComplete.current = new google.maps.places.Autocomplete(el, optionsRef.current);
            autoComplete.current.addListener('place_changed', handler);
            Location.ui.enableEnterKey(el, autoComplete.current);
            return () => google.maps.event.clearInstanceListeners(el);
        }
    }, [google]);

    return google ? (
        <div className={cx('locationsearchblock__body', className)}>
            <div className='locationsearchblock__input-container'> 
                <button
                    className="locateBtn searchBtn btn"
                    title={locationTip}
                    onClick={requestLocation}
                >
                    <i className="fas fa-crosshairs" aria-hidden="true" />
                </button>
                <input
                    className="searchinput border-0"
                    aria-label={placeholder || name}
                    placeholder={placeholder}
                    name={name}
                    type={type}
                    ref={inputRefRef.current}
                    value={value}
                    onChange={onChangeSearch}
                    onInput={onChangeSearch}
                />
                {value ? (
                    <button className="clearBtn searchBtn btn" title={clearTip} onClick={onClearInput}>
                        <i className="fas fa-times" aria-hidden="true" />
                    </button>
                ) : (
                    <button className="invisible clearBtn searchBtn btn">
                        <i className="invisible fas fa-times" aria-hidden="true" />
                    </button>
                )}
            </div>
            <button
                onClick={onSubmit}
                type="button"
                className="btn px-1"
                style={{ fontSize: '16px' }}
                disabled={!value}
                aria-label={submitLbl}
            >
                <i className="fa fa-arrow-right" />
            </button>
        </div>
    ) : (
        <div className={cx('LocationSearch', className)}>
            <Spinner />
        </div>
    );
});

export default LocationSearch;

LocationSearch.propTypes = {
    options: px.objectOf(px.any),
    type: px.string,
    placeholder: px.string,
    className: px.string,
    name: px.string,
    style: px.objectOf(px.any),
    storeLocatorPageUrl: px.string.isRequired
};
