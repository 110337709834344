import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { useRouteRegistry } from './hooks';

export default function Route({
    className,
    id,
    label,
    data,
    removeFromDomWhenInactive = false,
    onClosed,
    onOpened,
    children,
    noDomId = false,
    ...props
}) {
    const isActive = useRouteRegistry(id, label, data);
    const lastActive = React.useRef(isActive);

    React.useEffect(() => {
        if (lastActive.current !== isActive) {
            lastActive.current = isActive;
            if (onClosed && !isActive) onClosed(id);
            if (onOpened && isActive) onOpened(id);
        }
    }, [isActive, onClosed, onOpened, id]);

    return !removeFromDomWhenInactive || isActive ? (
        <div
            {...props}
            className={cx(`HashRoute hash-route-${id}`, { active: isActive }, className)}
            id={noDomId ? undefined : id}
        >
            {children}
        </div>
    ) : null;
}

Route.propTypes = {
    className: px.string,
    id: px.string.isRequired,
    label: px.string,
    data: px.objectOf(px.any),
    removeFromDomWhenInactive: px.bool,
    onClosed: px.func,
    onOpened: px.func,
    children: px.node,
    noDomId: px.bool,
};
