import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { useTranslation } from 'Common/hooks';
import { Tag } from 'Common/components/ui';
import { Currency } from 'Common/components/localization';

export default function GiftCards({ className, cards, onRemoveCard, currency }) {
    const lblClose = useTranslation('Commerce.Order.RemoveGiftCard.Label');

    return cards?.length ? (
        <div className={cx('GiftCards', className)}>
            {cards?.map(({ PaymentMethodId, CardNumber, Balance }) => (
                <Tag
                    lblClose={lblClose}
                    onClose={onRemoveCard(PaymentMethodId, CardNumber)}
                    key={`applied-giftcard-${CardNumber}`}
                >
                    <div>
                        {CardNumber} (<Currency code={currency} amount={Balance} />)
                    </div>
                </Tag>
            ))}
        </div>
    ) : null;
}

GiftCards.propTypes = {
    onRemoveCard: px.func,
    cards: px.arrayOf(px.shape({ CardNumber: px.string, PaymentMethodId: px.string, Balance: px.number })),
    currency: px.string,
    className: px.string,
};
