import * as React from 'react';
import px from 'prop-types';
import { useTranslation } from 'Common/hooks';
import { Translation } from 'Common/components/localization';
import { stopPropagation } from './helpers';

export const infoStyle = {
    main: {
        fontSize: '1.2em',
        whiteSpace: 'nowrap',
        backgroundColor: '#000',
        color: '#fff',
        padding: '1em',
    },
    close: {
        position: 'absolute',
        background: 'transparent',
        border: 'none',
        top: '.1em',
        right: '.1em',
        cursor: 'pointer',
        color: '#fff',
        fontSize: '1em',
    },
};

function stopProp(e) {
    e.stopPropagation();
}

/* eslint-disable react/prop-types */
export const InfoContainer = React.forwardRef(function Info(
    { className, children, show, style: { width = '175px', height = 'auto', backgroundColor = '#fff', ...rest } = {} },
    ref
) {
    const style = React.useMemo(
        () => ({
            width,
            height,
            backgroundColor,
            cursor: 'default',
            fontSize: '1em',
            overflow: 'hidden',
            transform: 'translate(-50%, -100%',
            position: 'absolute',
            marginTop: '-20px',
            zIndex: 4,
            ...rest,
        }),
        [width, rest, height, backgroundColor]
    );

    return show ? (
        <div className={className} onClick={stopProp} style={style} ref={ref}>
            {children}
        </div>
    ) : null;
});

// Info.propTypes = {
//     children: px.node,
//     show: px.bool,
//     style: px.objectOf(px.any),
//     className: px.string,
// };
/* eslint-enable react/prop-types */

export default function MapInfo({ show, location, onClose }) {
    const lblClose = useTranslation('Dealer.Locator.MapInfo.Close.Label');

    return (
        <InfoContainer show={show} style={{ width: 'auto', height: 'auto' }}>
            <div style={infoStyle.main}>
                <div style={{ marginBottom: '12px', fontWeight: 'bold' }}>{location.name}</div>
                <div style={{ marginBottom: '2px' }}>{location.address1}</div>
                <div style={{ marginBottom: '2px' }}>{location.address2}</div>
                <div style={{ marginBottom: '2px' }}>{location.phone}</div>
                {location.website ? (
                    <div style={{ marginBottom: '2px' }}>
                        <a
                            href={location.website}
                            target="_blank"
                            rel="noreferrer"
                            onClick={stopPropagation}
                            style={{ color: 'white' }}
                        >
                            {location.website.replace(/\?.*$/, '')}
                        </a>
                    </div>
                ) : null}
                <div>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        onClick={stopPropagation}
                        style={{ color: 'white' }}
                        href={location.directions}
                    >
                        <i className="fas fa-map-marked-alt" aria-hidden="true" />{' '}
                        <Translation id="Dealer.Locator.MapDirections.Label" />
                    </a>
                </div>
                <button style={infoStyle.close} onClick={onClose} aria-label={lblClose} title={lblClose}>
                    <i className="fas fa-times" />
                </button>
            </div>
        </InfoContainer>
    );
}

MapInfo.propTypes = {
    show: px.bool,
    onClose: px.func,
    location: px.shape({
        show: px.bool,
        id: px.oneOfType([px.string, px.number]),
        name: px.string,
        address1: px.string,
        address2: px.string,
        phone: px.string,
        website: px.string,
        directions: px.string,
    }),
};
