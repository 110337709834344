import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { Checkbox, Relevant, useFormApi, useFieldApi, Radio, RadioGroup, useFieldState } from 'informed';
import * as types from 'Common/types';
import { SHIPMENT, MONOFORM, PAYMENT, EMPTY_ADDRESS } from 'Common/constants/fields';
import { Translation } from 'Common/components/localization';
import { AddressForm, Field } from 'Common/components/forms';
import { AccordionDropdown } from 'Common/components/ui';
import PaymentOption from './PaymentOption';

export default function PaymentInformation({
    className,
    disabled,
    shipments = [],
    paymentMethods = [],
    siteId,
    marketId,
    currency,
    onBack,
    defaultShipment,
    initialPayment,
    requestError,
    clearRequestError,
    isLoggedIn = false,
    paymentAmount = 0.01,
}) {
    const lastBillingSwitch = React.useRef();
    const formApi = useFormApi();
    const billingSwitchApi = useFieldApi('_billing');

    const { value: billingSwitch } = useFieldState('_billing');
    const { value: token, pristine: tokenPristine } = useFieldState(`${MONOFORM.payment}.${PAYMENT.token}`);
    const { value: paymentType, pristine: typePristine } = useFieldState(`${MONOFORM.payment}.${PAYMENT.paymentType}`);

    const lastPaymentType = React.useRef(paymentType);
    const defaultAddress = React.useMemo(() => defaultShipment && defaultShipment[SHIPMENT.address], [defaultShipment]);
    const forceBillingInfo = React.useMemo(
        () =>
            (typePristine && typeof paymentType === 'undefined' ? initialPayment[PAYMENT.paymentType] : paymentType) ===
                'CreditCard' &&
            !!(tokenPristine && typeof token === 'undefined' ? initialPayment[PAYMENT.token] : token),
        [paymentType, token, initialPayment, tokenPristine, typePristine]
    );

    const lastForceBilling = React.useRef(forceBillingInfo);

    const onPaymentChange = React.useCallback(
        ({ value: nextPaymentType }) => {
            const currentSystemName = formApi.getValue(`${MONOFORM.payment}.${PAYMENT.systemName}`);
            const t = paymentMethods.find((m) => m[PAYMENT.paymentType] === nextPaymentType);

            if (lastPaymentType.current !== nextPaymentType || currentSystemName !== t[PAYMENT.systemName]) {
                clearRequestError();

                if (nextPaymentType === initialPayment[PAYMENT.paymentType] && initialPayment[PAYMENT.token]) {
                    formApi.setValue(MONOFORM.payment, {
                        ...initialPayment,
                        [PAYMENT.address]: { ...initialPayment[PAYMENT.address] },
                    });
                } else {
                    formApi.setValue(`${MONOFORM.payment}.${PAYMENT.token}`, '');
                }
                lastPaymentType.current = nextPaymentType;
                formApi.setValue(`${MONOFORM.payment}.${PAYMENT.systemName}`, t[PAYMENT.systemName]);
                formApi.setValue(`${MONOFORM.payment}.${PAYMENT.displayName}`, t[PAYMENT.displayName]);
                formApi.setValue(`${MONOFORM.payment}.${PAYMENT.paymentType}`, nextPaymentType);
            }
        },
        [formApi, initialPayment, paymentMethods, clearRequestError]
    );

    React.useEffect(() => {
        if (!billingSwitch && !billingSwitchApi?.getValue()) {
            billingSwitchApi.setValue(forceBillingInfo ? 'saved' : defaultAddress ? 'shipping' : 'custom');
        } else if (billingSwitch === 'custom' && lastBillingSwitch.current !== 'custom') {
            for (const k of Object.keys(EMPTY_ADDRESS)) {
                formApi.setValue(`${MONOFORM.payment}.${PAYMENT.address}.${k}`, EMPTY_ADDRESS[k]);
            }
        }
        lastBillingSwitch.current = billingSwitch;
    }, [defaultAddress, billingSwitch, billingSwitchApi, forceBillingInfo, formApi]);

    React.useEffect(() => {
        const saved = formApi.getValue(`${MONOFORM.payment}.${PAYMENT.savePayment}`);

        if (forceBillingInfo && lastForceBilling.current !== forceBillingInfo) {
            billingSwitchApi.setValue('saved');
            for (const k of Object.keys(EMPTY_ADDRESS)) {
                formApi.resetField(`${MONOFORM.payment}.${PAYMENT.address}.${k}`);
            }
            setTimeout(() =>
                formApi.setValue(`${MONOFORM.payment}.${PAYMENT.savePayment}`, paymentType === 'CreditCard')
            );
        } else if (lastForceBilling.current !== forceBillingInfo) {
            if (billingSwitchApi.getValue() === 'saved') {
                billingSwitchApi.setValue(defaultAddress ? 'shipping' : 'custom');
            }
            setTimeout(() =>
                formApi.setValue(`${MONOFORM.payment}.${PAYMENT.savePayment}`, paymentType === 'CreditCard' && saved)
            );
        }
        lastForceBilling.current = forceBillingInfo;
    }, [formApi, billingSwitchApi, forceBillingInfo, paymentType, token, defaultAddress]);

    React.useEffect(() => {
        if (paymentAmount <= 0) {
            formApi.reset(initialPayment);
        }
    }, [paymentAmount, formApi, initialPayment]);

    return (
        <div className={cx('PaymentInformation', className)}>
            {paymentAmount > 0 ? (
                <>
                    <section className="checkout-section">
                        <div className="checkout-section__title">
                            <Translation id="Commerce.Order.Checkout.Payment.Title" />
                        </div>
                        <div className="checkout-section__subtitle">
                            <Translation id="Commerce.Order.Checkout.Payment.Subtitle" />
                        </div>
                        <fieldset className="checkout-section__fieldset" disabled={disabled}>
                            <Field hidden name={`${MONOFORM.payment}.${PAYMENT.systemName}`} required />
                            <Field hidden name={`${MONOFORM.payment}.${PAYMENT.displayName}`} required />
                            <Field hidden name={`${MONOFORM.payment}.${PAYMENT.token}`} />
                            <Field
                                Component={RadioGroup}
                                name={`${MONOFORM.payment}.${PAYMENT.paymentType}`}
                                onChange={onPaymentChange}
                                required
                            >
                                <div className="checkout-grid">
                                    {paymentMethods?.map((method) => (
                                        <PaymentOption
                                            method={method}
                                            key={`pmt-opt-${method[PAYMENT.paymentMethodId]}-${
                                                method[PAYMENT.paymentType]
                                            }`}
                                            marketId={marketId}
                                            currency={currency}
                                            paymentAmount={paymentAmount}
                                            siteId={siteId}
                                            initialPayment={initialPayment}
                                            onChange={clearRequestError}
                                            defaultShipment={billingSwitch === 'shipping' ? defaultShipment : null}
                                            highlightError={
                                                requestError === 'Commerce.Order.Checkout.MissingPayment.Error'
                                            }
                                        />
                                    ))}
                                </div>
                            </Field>
                        </fieldset>
                    </section>

                    <section className="checkout-section">
                        <div className="checkout-section__title">
                            <Translation id="Commerce.Order.Checkout.BillingAddress.Title" />
                        </div>
                        <fieldset className="checkout-section__fieldset" disabled={disabled}>
                            <Field Component={RadioGroup} name="_billing" required>
                                <div className="checkout-grid">
                                    <button
                                        type="button"
                                        role="button"
                                        onClick={() => billingSwitchApi.setValue('shipping')}
                                        disabled={!defaultAddress || forceBillingInfo}
                                    >
                                        <div className="heading">
                                            <div className="radio d-flex">
                                                <Radio
                                                    value="shipping"
                                                    disabled={!defaultAddress || forceBillingInfo}
                                                    onClick={(e) => e.stopPropagation()}
                                                />
                                            </div>
                                            <div>
                                                <div>
                                                    <Translation id="Commerce.Order.Checkout.Payments.UseShippingAddress.Label" />
                                                </div>
                                            </div>
                                        </div>

                                        <Relevant
                                            when={({ formState }) => formState.values._billing === 'shipping'}
                                            relevanceWhen={['_billing']}
                                        >
                                            <AddressForm
                                                hidden
                                                scope={`${MONOFORM.payment}.${PAYMENT.address}`}
                                                address={billingSwitch === 'shipping' ? defaultAddress : null}
                                            />
                                        </Relevant>
                                    </button>
                                    <AccordionDropdown
                                        onHeaderClick={() => billingSwitchApi.setValue('custom')}
                                        open={billingSwitch === 'custom'}
                                        disabled={forceBillingInfo}
                                        bodyClass="row"
                                        header={
                                            <div className="heading">
                                                <div className="radio d-flex">
                                                    <Radio
                                                        value="custom"
                                                        onClick={(e) => e.stopPropagation()}
                                                        disabled={forceBillingInfo}
                                                    />
                                                </div>
                                                <div>
                                                    <div>
                                                        <Translation id="Commerce.Order.Checkout.Payments.UseNewAddress.Label" />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        controlled
                                    >
                                        <Relevant
                                            when={({ formState }) => formState.values._billing === 'custom'}
                                            relevanceWhen={['_billing']}
                                        >
                                            <AddressForm
                                                scope={`${MONOFORM.payment}.${PAYMENT.address}`}
                                                usePlaceholders
                                                siteId={siteId}
                                                halfInputsBreakpoint="xl"
                                                alternate
                                                connected
                                            />
                                        </Relevant>
                                    </AccordionDropdown>
                                    <button type="button" role="button" disabled>
                                        <div className="heading">
                                            <div className="radio d-flex">
                                                <Radio value="saved" disabled />
                                            </div>
                                            <div>
                                                <div>
                                                    <Translation id="Commerce.Order.Checkout.Payments.useSavedAddress.Label" />
                                                </div>
                                            </div>
                                        </div>
                                        <Relevant
                                            when={({ formState }) => formState.values._billing === 'saved'}
                                            relevanceWhen={['_billing']}
                                        >
                                            <AddressForm
                                                hidden
                                                scope={`${MONOFORM.payment}.${PAYMENT.address}`}
                                                saved="saved"
                                            />
                                        </Relevant>
                                    </button>
                                </div>
                            </Field>
                        </fieldset>
                    </section>

                    {isLoggedIn ? (
                        <section className="checkout-section">
                            <div className="checkout-section__title">
                                <Translation id="Form.Login.RememberMe" />
                            </div>
                            <fieldset className="checkout-section__fieldset row" disabled={disabled}>
                                <Field
                                    Component={Checkbox}
                                    className="col-12"
                                    label={
                                        <p>
                                            <Translation id="Commerce.Order.Checkout.Payments.SavePayment.Label" />
                                        </p>
                                    }
                                    name={`${MONOFORM.payment}.${PAYMENT.savePayment}`}
                                    labelInline
                                    labelLast
                                    hideError
                                    disabled={forceBillingInfo || paymentType !== 'CreditCard'}
                                />
                            </fieldset>
                        </section>
                    ) : null}
                </>
            ) : null}

            {requestError ? (
                <div className="alert alert-danger my-4">
                    <i className="fas fa-exclamation-triangle text-danger mr-2" />
                    <Translation id={requestError} />
                </div>
            ) : null}

            <div className="checkout-buttons">
                <button type="submit" role="submit" className="btn btn-lg btn-primary" disabled={!shipments?.length}>
                    <span>
                        <Translation id="Commerce.Order.Checkout.CompleteOrderButton.Label" />
                    </span>
                </button>
                <button type="button" role="button" className="btn btn-lg btn-inverted" onClick={onBack}>
                    <span>
                        <Translation id="Commerce.Order.Checkout.Buttons.PreviousSection.Label" />
                    </span>
                </button>
            </div>
        </div>
    );
}

PaymentInformation.propTypes = {
    className: px.string,
    disabled: px.bool,
    paymentMethods: px.arrayOf(types.PaymentMethod),
    defaultShipment: types.Shipment,
    shipments: px.arrayOf(types.Shipment),
    onBack: px.func,
    currency: px.string,
    marketId: px.string,
    paymentAmount: px.number,
    initialPayment: types.Payment,
    siteId: px.string,
    isLoggedIn: px.bool,
    requestError: px.string,
    clearRequestError: px.func,
};
