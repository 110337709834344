import React from 'react';
import px from 'prop-types';
import { FooterLocationSearch as SearchComponent } from 'Common/components/locator';

export default function FooterLocationSearch({ apiKey, region = 'us', storeLocatorPageUrl }) {


    return (
        <>
            <SearchComponent
                className="input__footer-store-locator-autocomplete"
                apiKey={apiKey}
                region={region}
                storeLocatorPageUrl={storeLocatorPageUrl}
            />
        </>
    );
}

FooterLocationSearch.propTypes = {
    apiKey: px.string.isRequired,
    region: px.string,
    storeLocatorPageUrl: px.string.isRequired,
};
