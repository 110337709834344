import { useState, useRef, useEffect } from 'react';
import { Url } from 'Common/utils';

export default function useLocationHash({ noHashtag = false, asCommaSeparatedArray = false } = {}) {
    const [state, setState] = useState(
        asCommaSeparatedArray ? Url.current.hashes : noHashtag ? Url.current.hash.slice(1) : Url.current.hash
    );

    const optsRef = useRef({ noHashtag, array: asCommaSeparatedArray });

    useEffect(() => {
        function handler(url) {
            setState(optsRef.current.array ? url.hashes : optsRef.current.noHashtag ? url.hash.slice(1) : url.hash);
        }

        Url.onHashChange(handler);
        return () => Url.offHashChange(handler);
    }, []);

    useEffect(() => {
        optsRef.current = { noHashtag, array: asCommaSeparatedArray };
    }, [noHashtag, asCommaSeparatedArray]);

    return state;
}
