import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';

export default function Badge({
    children,
    className,
    horizontal = 'left',
    vertical = 'top',
    size,
    style = {},
    ...props
}) {
    const justify = React.useMemo(() => {
        switch (horizontal.toLowerCase()) {
            case 'left':
            case 'start':
                return 'flex-start';
            case 'right':
            case 'end':
                return 'flex-end';
            default:
                return 'center';
        }
    }, [horizontal]);

    const align = React.useMemo(() => {
        switch (vertical.toLowerCase()) {
            case 'top':
            case 'start':
                return 'flex-start';
            case 'bottom':
            case 'end':
                return 'flex-end';
            default:
                return 'center';
        }
    }, [vertical]);

    const styleProps = React.useMemo(
        () => ({
            ...style,
            fontSize: size ?? style.fontSize,
            justifyContent: justify,
            alignItems: align,
        }),
        [size, style, justify, align]
    );

    return (
        <div className={cx('Badge d-flex flex-row', className)} style={styleProps} {...props}>
            <div className="Badge__content">{children}</div>
        </div>
    );
}

Badge.propTypes = {
    style: px.objectOf(px.any),
    children: px.node,
    className: px.string,
    vertical: px.oneOf(['top', 'start', 'middle', 'center', 'bottom', 'end']),
    horizontal: px.oneOf(['left', 'start', 'center', 'middle', 'right', 'end']),
    size: px.oneOfType([px.string, px.number]),
};
