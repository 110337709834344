import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { Relevant, useFieldApi, Radio, useFieldState } from 'informed';
import * as types from 'Common/types';
import { MONOFORM, PAYMENT } from 'Common/constants/fields';
import { CreditCardIssuers } from 'Common/components/forms/Payment/display';
import { PaymentForm } from 'Common/components/forms';
import { AccordionDropdown, Icon } from 'Common/components/ui';

const paymentTypeFieldName = `${MONOFORM.payment}.${PAYMENT.paymentType}`;

function stopPropagation(e) {
    e.stopPropagation();
}

export function PaymentLogo({ method }) {
    switch (method[PAYMENT.paymentType]) {
        case 'GooglePay':
            return <Icon sheet="payment" name="google-pay" label="PaymentType.GooglePay.DisplayName" />;
        case 'ApplePay':
            return <Icon sheet="payment" name="apple-pay" label="PaymentType.ApplePlay.DisplayName" />;
        case 'AmazonPay':
            return <Icon sheet="payment" name="amazon-pay-large" label="PaymentType.AmazonPlay.DisplayName" />;
        case 'PayPal':
            return <Icon sheet="payment" name="paypal-large" label="PaymentType.PayPal.DisplayName" />;
        default:
            return <h6>{method[PAYMENT.displayName]}</h6>;
    }
}

PaymentLogo.propTypes = {
    method: types.PaymentMethod,
};

export default function PaymentOption({
    method,
    marketId,
    currency,
    initialPayment,
    paymentAmount,
    defaultShipment,
    onChange,
    highlightError = false,
}) {
    const api = useFieldApi(paymentTypeFieldName);
    const { value: paymentType } = useFieldState(paymentTypeFieldName);
    const active = React.useMemo(() => paymentType === method[PAYMENT.paymentType], [method, paymentType]);
    const onClick = React.useCallback(() => api.setValue(method[PAYMENT.paymentType]), [api, method]);

    return (
        <AccordionDropdown
            onHeaderClick={onClick}
            open={active}
            disabled={active}
            header={
                <div className="heading">
                    <div className="radio d-flex">
                        <Radio value={method[PAYMENT.paymentType]} onClick={stopPropagation} />
                    </div>
                    <div className={cx('payment-logo', method[PAYMENT.paymentType])}>
                        <PaymentLogo method={method} />

                        {method[PAYMENT.paymentType] === 'CreditCard' ? (
                            <div>
                                <CreditCardIssuers options={method.Options} scope={MONOFORM.payment} />
                            </div>
                        ) : null}
                    </div>
                </div>
            }
            controlled
        >
            <Relevant
                when={({
                    formState: { values: { [MONOFORM.payment]: { [PAYMENT.paymentType]: type } = {} } = {} } = {},
                }) => type === method[PAYMENT.paymentType]}
                relevanceWhen={[paymentTypeFieldName, MONOFORM.payment]}
            >
                <PaymentForm
                    className={cx({ error: highlightError })}
                    onChange={onChange}
                    method={method}
                    marketId={marketId}
                    scope={MONOFORM.payment}
                    currency={currency}
                    paymentAmount={paymentAmount}
                    initialPayment={initialPayment}
                    defaultShipment={defaultShipment}
                    formClass="row"
                    useAlternateForms
                    usePlaceholders
                />
            </Relevant>
        </AccordionDropdown>
    );
}

PaymentOption.propTypes = {
    onChange: px.func,
    method: types.PaymentMethod,
    highlightError: px.bool,
    marketId: px.string,
    currency: px.string,
    defaultShipment: types.Shipment,
    initialPayment: px.shape({ token: px.string, type: px.string }),
    paymentAmount: px.number,
};
