import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { useTranslation } from 'Common/hooks';
import { Tag } from 'Common/components/ui';

export default function Promotions({ className, promotions, onRemovePromo }) {
    const lblClose = useTranslation('Commerce.Order.RemovePromo.Label');

    return promotions?.length ? (
        <div className={cx('Promotions', className)}>
            {promotions?.map((p) => (
                <Tag lblClose={lblClose} onClose={onRemovePromo(p)} key={`applied-promo-${p}`}>
                    <div>{p}</div>
                </Tag>
            ))}
        </div>
    ) : null;
}

Promotions.propTypes = {
    promotions: px.arrayOf(px.string),
    onRemovePromo: px.func,
    className: px.string,
};
