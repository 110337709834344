import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import $ from 'jquery';
import * as types from 'Common/types';
import { useTranslation, useViewport } from 'Common/hooks';
import { CartListing } from 'Common/components/cart/InlineCart';
import { Translation, Currency } from 'Common/components/localization';
import { Icon } from 'Common/components/ui';

const EM = 16;

export default function CheckoutSummary({
    className,
    forms = [],
    summaryData,
    loaded = false,
    currency,
    emptyLabel = 'Commerce.Order.Cart.EmptyCart.Label',
    gtmListValue,
    giftCardProps,
    promoProps,
    recapData = null,
    onBackToCustomer,
    onBackToShipping,
    loading = false,
    cartId,
    totalQty = 0,
    ...props
}) {
    const viewport = useViewport();
    const [collapsed, setCollapsed] = React.useState(true);
    const [maxHeight, setMaxHeight] = React.useState('none');
    const toggleLbl = useTranslation('Commerce.Order.Checkout.PaymentSummary.Toggle.Label');

    const orderDiscounts = React.useMemo(
        () =>
            summaryData.discounts?.map((c) => ({
                ...c,
                value: c.type === 'Order' ? (c.value > 0 ? c.value * -1 : c.value) : '',
            })),
        [summaryData]
    );

    const shippingDiscounts = React.useMemo(
        () =>
            summaryData.discounts
                ?.filter((c) => c.type === 'Shipping')
                .map((c) => ({ ...c, value: c.value > 0 ? c.value * -1 : c.value })),
        [summaryData]
    );

    const priceInfo = React.useMemo(
        () =>
            [
                { label: 'Commerce.Order.Detail.Summary.Subtotal.Label', value: summaryData.subtotal },
                orderDiscounts?.length
                    ? {
                          label: 'Commerce.Order.Detail.Summary.OrderDiscount.Label',
                          value:
                              summaryData.orderDiscount > 0
                                  ? summaryData.orderDiscount * -1
                                  : summaryData.orderDiscount,
                          children: orderDiscounts,
                          id: 'discount',
                      }
                    : null,
                { label: 'Commerce.Order.Detail.Summary.Shipping.Label', value: summaryData.shipping },
                summaryData.handling
                    ? { label: 'Commerce.Order.Checkout.CheckoutSummary.Handling.Label', value: summaryData.handling }
                    : null,
                shippingDiscounts?.length
                    ? {
                          label: 'Commerce.Order.Detail.Summary.ShippingDiscount.Label',
                          value:
                              summaryData.shippingDiscount > 0
                                  ? summaryData.shippingDiscount * -1
                                  : summaryData.shippingDiscount,
                          children: shippingDiscounts,
                          id: 'shippingDiscount',
                      }
                    : null,
                { label: 'Commerce.Order.Detail.Summary.Tax.Label', value: summaryData.tax },
                summaryData.giftCardDiscount
                    ? {
                          label: 'Commerce.Order.Checkout.CheckoutSummary.GiftCardTotal.Label',
                          value:
                              summaryData.giftCardDiscount > 0
                                  ? summaryData.giftCardDiscount * -1
                                  : summaryData.giftCardDiscount,
                      }
                    : null,
            ].filter(Boolean),
        [summaryData, shippingDiscounts, orderDiscounts]
    );

    const splitShipments = React.useMemo(
        () => !!forms.find((f) => f.Shipments.filter((s) => s.LineItems.length).length > 1),
        [forms]
    );

    const onToggle = React.useCallback(() => {
        setCollapsed(!collapsed);
    }, [collapsed]);

    const recapWrapper = React.useCallback(
        (fn) => (e) => {
            if (viewport.is.gt('xs') || e.target?.classList?.contains('edit-action')) {
                fn();
            }
        },
        [viewport]
    );

    React.useEffect(() => {
        if (loading) return; // do not calculate while loading
        setTimeout(() => {
            const recapHeight = $('.CheckoutSummary > .recap-container')?.height() || 0;
            const approxHeight =
                $('.CheckoutSummary__head').height() +
                recapHeight +
                $('.CheckoutSummary__listing').height() +
                2 * EM +
                2 * 0.875 * EM;

            if (!viewport.is.mobile) {
                setMaxHeight('none');
            } else if (collapsed) {
                setMaxHeight(approxHeight);
                setTimeout(() => setMaxHeight($('.CheckoutSummary__head').height()));
            } else {
                setMaxHeight(approxHeight);
                setTimeout(() => setMaxHeight('none'), 355);
            }
        });
    }, [collapsed, viewport, loading]);

    return (
        <div className={cx('CheckoutSummary', { collapsed }, className)} style={{ maxHeight }} {...props}>
            <div className="CheckoutSummary__head">
                <div>
                    <div className="title">
                        <Translation id="Commerce.Order.Checkout.PaymentSummary.Title" />
                    </div>
                    <div className="d-block d-lg-none">
                        <button
                            className="btn d-flex d-row CheckoutSummary__head__collapse-btn"
                            role="button"
                            type="button"
                            onClick={onToggle}
                            aria-label={toggleLbl}
                        >
                            {loaded ? <Currency code={currency} amount={summaryData.total} /> : null}
                            <Icon className={collapsed ? null : 'flip'} name="caret-down" />
                        </button>
                    </div>
                </div>
            </div>

            {recapData ? (
                <div className="recap-container">
                    {recapData.contact && onBackToCustomer ? (
                        <button
                            type="button"
                            role="button"
                            className="recap"
                            onClick={recapWrapper(onBackToCustomer)}
                            disabled={recapData.disabled}
                        >
                            <div>
                                <div>
                                    <strong>
                                        <Translation id="Commerce.Order.Checkout.Recap.Contact.Label" />
                                    </strong>
                                </div>
                                <div className="recap-value">{recapData.contact}</div>
                            </div>

                            <div className="edit-action">
                                {recapData.canEditContact ? null : (
                                    <span className="edit-action">
                                        <Translation id="Commerce.Order.Checkout.Recap.ChangeButton.Label" />
                                    </span>
                                )}
                            </div>
                        </button>
                    ) : null}

                    {recapData.shipments?.length === 1 && onBackToCustomer ? (
                        <>
                            <button
                                type="button"
                                role="button"
                                className="recap"
                                onClick={recapWrapper(onBackToCustomer)}
                                disabled={recapData.disabled}
                            >
                                <div>
                                    <div>
                                        <strong>
                                            <Translation
                                                id={
                                                    recapData.shipments[0].digital
                                                        ? 'Commerce.Order.Checkout.Recap.DigitalAddress.Label'
                                                        : 'Commerce.Order.Checkout.Recap.ShippingAddress.Label'
                                                }
                                            />
                                        </strong>
                                    </div>
                                    <div className="recap-value">{recapData.shipments[0].address}</div>
                                </div>
                                <div className="edit-action">
                                    <span className="edit-action">
                                        <Translation id="Commerce.Order.Checkout.Recap.ChangeButton.Label" />
                                    </span>
                                </div>
                            </button>
                            {!recapData.shipments[0].digital && recapData.shipments[0].method && onBackToShipping ? (
                                <button
                                    type="button"
                                    role="button"
                                    className="recap"
                                    onClick={recapWrapper(onBackToShipping)}
                                    disabled={recapData.disabled}
                                >
                                    <div>
                                        <div>
                                            <strong>
                                                <Translation id="Commerce.Order.Checkout.Recap.ShippingMethod.Label" />
                                            </strong>
                                        </div>
                                        <div className="recap-value">{recapData.shipments[0].method}</div>
                                    </div>
                                    <div className="edit-action">
                                        <span className="edit-action">
                                            <Translation id="Commerce.Order.Checkout.Recap.ChangeButton.Label" />
                                        </span>
                                    </div>
                                </button>
                            ) : null}
                            {recapData.shipments[0].gift && onBackToShipping ? (
                                <button
                                    type="button"
                                    role="button"
                                    className="recap"
                                    onClick={recapWrapper(onBackToShipping)}
                                    disabled={recapData.disabled}
                                >
                                    <div>
                                        <div>
                                            <strong>
                                                <Translation id="Commerce.Order.Checkout.GiftMessage.Display" />
                                            </strong>
                                        </div>
                                        <div className="recap-value">{recapData.shipments[0].gift}</div>
                                    </div>
                                    <div className="edit-action">
                                        <span className="edit-action">
                                            <Translation id="Commerce.Order.Checkout.Recap.ChangeButton.Label" />
                                        </span>
                                    </div>
                                </button>
                            ) : null}
                        </>
                    ) : null}
                </div>
            ) : null}

            <CartListing
                className="CheckoutSummary__listing"
                forms={forms}
                total={summaryData.totalSansGiftCards}
                loaded={loaded}
                currency={currency}
                emptyLabel={emptyLabel}
                priceInfo={priceInfo}
                loading={loading}
                splitShipments={splitShipments}
                promoProps={promoProps}
                giftCardProps={giftCardProps}
                recapData={recapData}
                onBackToCustomer={onBackToCustomer}
                onBackToShipping={onBackToShipping}
                cartId={cartId}
                includeTotalCount
                noHeader
                totalQty={totalQty}
            ></CartListing>
        </div>
    );
}

CheckoutSummary.propTypes = {
    recapData: px.shape({
        contact: px.string,
        canEditContact: px.bool,
        disabled: px.bool,
        shipments: px.arrayOf(
            px.shape({
                digital: px.bool,
                address: px.string,
                method: px.string,
                gift: px.string,
            })
        ),
    }),
    onBackToCustomer: px.func,
    onBackToShipping: px.func,
    loaded: px.bool,
    className: px.string,
    forms: px.arrayOf(types.CartForm),
    emptyLabel: px.string,
    currency: px.string,
    gtmListValue: px.string,
    loading: px.bool,
    promoProps: px.any,
    giftCardProps: px.any,
    summaryData: types.OrderSummary,
    cartId: px.node,
    totalQty: px.number,
};
