import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { Checkbox, Relevant } from 'informed';
import * as types from 'Common/types';
import { Email } from 'Common/utils';
import { EMAIL, SHIPMENT, MONOFORM } from 'Common/constants/fields';
import { Field, AddressForm } from 'Common/components/forms';
import { Translation } from 'Common/components/localization';

export default function CustomerInformation({
    className,
    disabled,
    isLoggedIn,
    signinLink,
    signupLink,
    signoutLink,
    onLocationChange,
    savedAddresses = [],
    shipments = [],
    requestError,
    onBack,
    siteId,
}) {
    const physicalCount = React.useMemo(() => shipments?.filter((s) => !s[SHIPMENT.isDigital])?.length ?? 0, [
        shipments,
    ]);

    const locationChangeIndex = React.useCallback(
        (index) => (onLocationChange ? (...args) => onLocationChange(index, ...args) : null),
        [onLocationChange]
    );

    return (
        <div className={cx('CustomerInformation', className)}>
            <section className="checkout-section">
                <div className="checkout-section__title">
                    <Translation id="Commerce.Order.Checkout.Email.Title" />
                </div>
                <fieldset className="checkout-section__fieldset row" disabled={disabled}>
                    <Field
                        placeholder="Commerce.Order.Checkout.Email.Input.Label"
                        className="col-12"
                        name={`${MONOFORM.customer}.${EMAIL.email}`}
                        validate={Email.getValidationError}
                        disabled={isLoggedIn}
                        validateOnBlur
                        required
                    />
                    <Field
                        Component={Checkbox}
                        className="col-12"
                        label={
                            <p>
                                <Translation id="Commerce.Order.Checkout.Email.Signup.Label" />
                            </p>
                        }
                        name={`${MONOFORM.customer}.${EMAIL.signup}`}
                        labelInline
                        labelLast
                        hideError
                    />

                    <div className="col-12 email-links">
                        {isLoggedIn ? (
                            <p>
                                <span>
                                    <Translation id="Commerce.Order.Checkout.Email.WrongUserMessage.Label" />
                                </span>
                                <a href={signoutLink}>
                                    <Translation id="Commerce.Order.Checkout.Email.LogoutButton.Label" />
                                </a>
                            </p>
                        ) : (
                            <>
                                <p>
                                    <span className="d-none d-sm-inline">
                                        <Translation id="Commerce.Order.Checkout.Email.ReturningUserMessage.Label" />
                                    </span>
                                    <a href={signinLink}>
                                        <Translation id="Commerce.Order.Checkout.Email.LoginButton.Label" />
                                    </a>
                                </p>
                                <p className="d-block d-sm-none color-primary m-0">|</p>
                                <p>
                                    <span className="d-none d-sm-inline">
                                        <Translation id="Commerce.Order.Checkout.Email.NewUserMessage.Label" />
                                    </span>
                                    <a href={signupLink}>
                                        <Translation id="Commerce.Order.Checkout.Email.RegisterButton.Label" />
                                    </a>
                                </p>
                            </>
                        )}
                    </div>
                </fieldset>
            </section>

            {shipments?.map((shipment, index) => (
                <section className="checkout-section" key={`checkout_shipment_${shipment[SHIPMENT.shipmentId]}`}>
                    <div className="checkout-section__title">
                        {shipments.length > 1 ? (
                            <Translation
                                id="Commerce.Order.Checkout.Shipment.Split.Title"
                                params={{ index: index + 1 }}
                            />
                        ) : (
                            <Translation id="Commerce.Order.Checkout.Shipment.Title" />
                        )}
                    </div>
                    <fieldset className="checkout-section__fieldset row" disabled={disabled}>
                        <Field
                            hidden
                            required
                            name={`${MONOFORM.shipments}[${index}].${SHIPMENT.shipmentId}`}
                            value={shipment[SHIPMENT.shipmentId]}
                        />
                        <Field
                            hidden
                            name={`${MONOFORM.shipments}[${index}].${SHIPMENT.isDigital}`}
                            value={shipment[SHIPMENT.isDigital]}
                        />
                        <Field
                            hidden
                            name={`${MONOFORM.shipments}[${index}].${SHIPMENT.isGift}`}
                            value={shipment[SHIPMENT.isGift]}
                        />
                        <Field
                            hidden
                            name={`${MONOFORM.shipments}[${index}].${SHIPMENT.giftMessage}`}
                            value={shipment[SHIPMENT.giftMessage]}
                        />
                        <Relevant when={() => shipment[SHIPMENT.isDigital]}>
                            <AddressForm
                                scope={`${MONOFORM.shipments}[${index}].${SHIPMENT.address}`}
                                stretchEmail
                                usePlaceholders
                                digital
                            />
                        </Relevant>
                        <Relevant when={() => !shipment[SHIPMENT.isDigital]}>
                            <AddressForm
                                savedAddressSelectorClassName="col-12"
                                savedAddresses={savedAddresses}
                                useDefaultCheck={isLoggedIn && physicalCount === 1}
                                scope={`${MONOFORM.shipments}[${index}].${SHIPMENT.address}`}
                                onLocationChange={locationChangeIndex(index)}
                                usePlaceholders
                                siteId={siteId}
                                halfInputsBreakpoint="xl"
                                connected
                                alternate
                            />
                        </Relevant>
                    </fieldset>
                </section>
            ))}

            {requestError ? (
                <div className="alert alert-danger my-4">
                    <i className="fas fa-exclamation-triangle text-danger mr-2" />
                    <Translation id={requestError} />
                </div>
            ) : null}

            <div className="checkout-buttons">
                <button type="submit" role="submit" className="btn btn-lg btn-primary" disabled={!shipments?.length}>
                    <span>
                        <Translation id="Commerce.Order.Checkout.Buttons.ContinueToShipping.Label" />
                    </span>
                </button>
                <button type="button" role="button" className="btn btn-lg btn-inverted" onClick={onBack}>
                    <span>
                        <Translation id="Block.OrderConfirmation.ContinueShipping.Button.Text" />
                    </span>
                </button>
            </div>
        </div>
    );
}

CustomerInformation.propTypes = {
    className: px.string,
    disabled: px.bool,
    isLoggedIn: px.bool,
    signinLink: px.string,
    signupLink: px.string,
    savedAddresses: px.arrayOf(types.Address),
    onLocationChange: px.func,
    shipments: px.arrayOf(types.Shipment),
    siteId: px.string,
    onBack: px.func,
    signoutLink: px.string,
    requestError: px.string,
};
