import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { useFieldState } from 'informed';
import * as types from 'Common/types';
import { PAYMENT } from 'Common/constants/fields';
import { Translation } from 'Common/components/localization';
import { AccordionDropdown } from 'Common/components/ui';
import { PaymentDisplay } from './display';

export default function SavedPaymentSelector({
    className,
    payments,
    scope = '',
    defaultExpanded = false,
    paymentType,
    onNewPayment,
    onLoadPayment,
    cardSeparator = '-',
    showCurrent = false,
}) {
    const ref = React.useRef();
    const scopeStr = React.useMemo(() => (scope ? `${scope}.` : ''), [scope]);
    const { value: token } = useFieldState(`${scopeStr}${PAYMENT.token}`);
    const usingSavedPayment = React.useMemo(() => (token && payments.find((a) => a[PAYMENT.token] === token)) || null, [
        token,
        payments,
    ]);

    return (
        <AccordionDropdown
            className={cx('PaymentSelector', className)}
            title="Commerce.Order.Checkout.SavedCreditCard.Dropdown.Label"
            ref={ref}
            defaultExpanded={defaultExpanded}
        >
            {onNewPayment ? (
                <button
                    type="button"
                    role="button"
                    onClick={onNewPayment}
                    className="btn btn-select"
                    disabled={!usingSavedPayment}
                >
                    <span>
                        <Translation id="Commerce.Order.Checkout.SavedCreditCard.Dropdown.AddNew.Label" />
                    </span>
                </button>
            ) : null}
            {usingSavedPayment && showCurrent ? (
                <button type="button" role="button" className="btn btn-select disabled" disabled>
                    <PaymentDisplay
                        type={paymentType}
                        name={usingSavedPayment[PAYMENT.customerName]}
                        number={usingSavedPayment[PAYMENT.cardNumber]}
                        issuer={usingSavedPayment[PAYMENT.cardType]}
                        month={usingSavedPayment[PAYMENT.expMonth]}
                        year={usingSavedPayment[PAYMENT.expYear]}
                        separator={cardSeparator}
                    />
                </button>
            ) : null}
            {payments.map((p) =>
                p[PAYMENT.token] === token ? null : (
                    <button
                        type="button"
                        role="button"
                        className="btn btn-select"
                        key={p[PAYMENT.token]}
                        onClick={() => onLoadPayment(p)}
                    >
                        <PaymentDisplay
                            name={p[PAYMENT.customerName]}
                            number={p[PAYMENT.cardNumber]}
                            type={paymentType}
                            issuer={p[PAYMENT.cardType]}
                            month={p[PAYMENT.expMonth]}
                            year={p[PAYMENT.expYear]}
                            separator={cardSeparator}
                        />
                    </button>
                )
            )}
        </AccordionDropdown>
    );
}

SavedPaymentSelector.propTypes = {
    payments: px.arrayOf(types.SavedPayment),
    scope: px.string,
    className: px.string,
    defaultExpanded: px.bool,
    onNewPayment: px.func,
    onLoadPayment: px.func,
    paymentType: px.string,
    showCurrent: px.bool,
    cardSeparator: px.string,
};
