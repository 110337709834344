import * as React from 'react';
import px from 'prop-types';
import { useFieldState } from 'informed';
import { PAYMENT } from 'Common/constants/fields';
import PaymentDisplay from './PaymentDisplay';

export default function CurrentPaymentDisplay({ scope = '', type: typeProp, ...props }) {
    const scopeStr = React.useMemo(() => (scope ? `${scope}.` : ''), [scope]);
    const { value: paymentType } = useFieldState(`${scopeStr}${PAYMENT.paymentType}`);
    const { value: cardType } = useFieldState(`${scopeStr}${PAYMENT.cardType}`);
    const { value: cardNumber } = useFieldState(`${scopeStr}${PAYMENT.cardNumber}`);
    const { value: customerName } = useFieldState(`${scopeStr}${PAYMENT.customerName}`);
    const { value: expMonth } = useFieldState(`${scopeStr}${PAYMENT.expMonth}`);
    const { value: expYear } = useFieldState(`${scopeStr}${PAYMENT.expYear}`);

    return (
        <PaymentDisplay
            issuer={cardType}
            type={typeProp ?? paymentType}
            number={cardNumber}
            name={customerName}
            year={expYear}
            month={expMonth}
            {...props}
        />
    );
}

CurrentPaymentDisplay.propTypes = {
    scope: px.string,
    initialPayment: px.shape({ token: px.string, type: px.string }),
    type: px.string,
    ...PaymentDisplay.propTypes,
};
