import * as React from 'react';
import px from 'prop-types';
import $ from 'jquery';
import { useLocationHash } from 'Common/hooks';
import { Url } from 'Common/utils';
import context from './context';
import { reducer, actions, selectors, initialState } from './duck';

/**
 * Router
 * @param {function} props.onChange - Gets next hash and prev hash as args. If return value is truthy, the route change
 *      is prevented. If the return is an available hash, location will be re-routed to specified hash.
 */
export default function Router({ children, onChange, useJqueryScroll = null }) {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const hashes = useLocationHash({ asCommaSeparatedArray: true });
    const routes = React.useMemo(() => selectors.getAllRoutes(state), [state]);
    const lastHashes = React.useRef(null);
    const lastActive = React.useRef();

    React.useEffect(() => {
        if (routes.length) {
            const indexRouteId = routes.find((r) => r.data?.index)?.id;
            const all = hashes.filter((h) => routes.find((r) => r.id === h));
            const str = all.join(',');

            if (str !== lastHashes.current) {
                lastHashes.current = str;
                if (all.length) {
                    const next = all.pop();
                    let url;

                    for (const other of all) url = (url || Url.current).delHash(other);

                    if (lastActive.current !== next) {
                        const initialLoad = !lastActive.current;
                        const change = onChange && onChange(next, lastActive.current);

                        if (!change) {
                            lastActive.current = next;
                            dispatch(actions.setActiveRoute(next));

                            if (useJqueryScroll) {
                                const { speed = 'fast', offset = 0, topOnInitialLoad = false } = useJqueryScroll;

                                if (initialLoad && topOnInitialLoad) {
                                    setTimeout(() => {
                                        $('html').animate({ scrollTop: 0 }, speed);
                                    });
                                } else {
                                    setTimeout(() => {
                                        const el = $(`.hash-route-${next}`);

                                        if (el.length) {
                                            $('html').animate({ scrollTop: el.position().top + offset }, speed);
                                        }
                                    });
                                }
                            }
                        } else {
                            url = (url || Url.current)
                                .delHash(next)
                                .addHash(routes.find((r) => r.id === change) ? change : lastActive.current);
                        }
                    }

                    if (url) url.apply();
                } else if (lastActive.current !== indexRouteId) {
                    if (indexRouteId) {
                        Url.current.addHash(indexRouteId).apply();
                    } else {
                        lastActive.current = undefined;
                        dispatch(actions.clearActiveRoute());
                    }
                }
            }
        }
    }, [hashes, routes, dispatch, onChange, useJqueryScroll]);

    return <context.Provider value={{ state, dispatch }}>{children}</context.Provider>;
}

Router.propTypes = {
    children: px.node,
    onChange: px.func,
    useJqueryScroll: px.shape({ speed: px.number, offset: px.number }),
};
