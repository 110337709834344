import * as React from 'react';
import px from 'prop-types';
import { usePayPal, usePaymentToken } from 'Common/hooks/payment';
import { Translation } from 'Common/components/localization';
import { Spinner } from 'Common/components/ui';

export default function PayPal({ parentId, options, scope, currency, paymentAmount = 0.01, onChange }) {
    const { loaded, token } = usePayPal({ options, currency, paymentAmount, id: `${parentId}-buttons` });
    const lastToken = React.useRef(token);

    usePaymentToken(token, scope);

    React.useEffect(() => {
        if (token !== lastToken.current) {
            lastToken.current = token;
            if (token && onChange) onChange();
        }
    }, [token, onChange]);

    return (
        <>
            {/* <div className="PayPalTitle">
                <Translation id="Commerce.Order.Checkout.PayPal.Content" />
            </div> */}
            {loaded ? null : (
                <div className="PayPalLoading">
                    <Translation id="Commerce.Order.Checkout.PayPal.Waiting" />
                    <Spinner />
                </div>
            )}
            <div className="PayPalButtonsContainer">
                <div className={loaded ? 'd-block' : 'd-none'} id={`${parentId}-buttons`}></div>
            </div>
        </>
    );
}

PayPal.propTypes = {
    options: px.shape({
        Sandbox: px.string,
        MerchantId: px.oneOfType([px.string, px.number]),
        ClientId: px.string.isRequired,
    }).isRequired,
    scope: px.string,
    parentId: px.string.isRequired,
    currency: px.string.isRequired,
    paymentAmount: px.number,
    onChange: px.func,
};
