import * as React from 'react';
import { useSelector } from 'react-redux';
import { CartSummaryFooter as Summary } from 'Common/components/cart';
import { order } from '~features';

export default function CartSummaryFooter() {
    const cartSummaryFooterData = useSelector(order.selectors.getCartSummaryFooterData);

    return <Summary cartSummaryFooterData={cartSummaryFooterData} />;
}
