import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { useAction, useViewport, useTranslation, useResize, useUrlParam, useScript } from 'Common/hooks';
import { logger } from 'Common/core';
import { UNIT } from 'Common/constants/locator';
import { Location } from 'Common/utils';
import { LocatorMap, LocationSearch, LocatorList } from 'Common/components/locator';
import { Loader, UserMessage } from 'Common/components/ui';
import { locator } from '~features';

const DEFAULT_ERROR = 'Unknown Error';

export default function StoreLocator({
    brandName,
    apiKey,
    unit = UNIT.MILE,
    defaultLat = 34.0,
    defaultLng = -81.0,
    defaultAddr = 'Columbia, SC, USA',
    disclaimerText,
    language,
    categories,
}) {
    const initialLat = useUrlParam('lat', true);
    const initialLng = useUrlParam('lng', true);
    const initialAddr = useUrlParam('addr', true);
    const ctrlRef = React.useRef();
    const container = React.useRef();
    const viewport = useViewport();
    const dims = useResize();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const searchState = useSelector(locator.selectors.getSearchState);
    const mapState = useSelector(locator.selectors.getMapState);
    const updateMap = useAction(locator.actions.updateMap);
    const updateSearch = useAction(locator.actions.updateSearch);
    const updateQuery = useAction(locator.actions.updateQuery);
    const startLocationSearch = useAction(locator.actions.startLocationSearch);
    const nextSearchPage = useAction(locator.actions.nextSearchPage);
    const searchPlaceholder = useTranslation('Dealer.Locator.Search.Placeholder');
    const [innerHeight, setInnerHeight] = React.useState(dims.height - 50);
    const googleRef = React.useRef(null);
    const initialLoc = React.useRef(null);

    const google = useScript(
        apiKey ? `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&region=us` : null,
        {
            onLoad: async (lib) => {
                const maps = await lib.maps.importLibrary('maps');

                lib.maps = { ...lib.maps, ...maps };
                return lib;
            },
            globalNamespace: 'google',
            delay: 2000,
            destroyOnDismount: false,
        }
    );

    const onSearchInput = React.useCallback((e) => updateSearch({ input: e.target.value }), [updateSearch]);

    const onClearSearchInput = React.useCallback(() => updateSearch({ input: '' }), [updateSearch]);

    const clearLocation = React.useCallback(() => updateSearch({ location: null, input: '' }), [updateSearch]);

    const loadMore = React.useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            await nextSearchPage();
        } catch (e) {
            setError({ ts: Date.now(), message: e?.message ?? typeof e === 'string' ? e : DEFAULT_ERROR });
        }
        setLoading(false);
    }, [nextSearchPage]);

    const searchLocation = React.useCallback(
        async (loc) => {
            let res = false;

            setLoading(true);
            setError(null);
            if (!loc.address && !loc.fullAddress && googleRef.current) {
                loc.fullAddress = await Location.addressFromCoords(loc, googleRef.current, false);
            }
            updateSearch({ input: loc.fullAddress ?? loc.address ?? '' });
            try {
                await startLocationSearch(loc);
                res = true;
            } catch (e) {
                setError({ ts: Date.now(), message: e?.message ?? typeof e === 'string' ? e : DEFAULT_ERROR });
            }
            setLoading(false);
            return res;
        },
        [startLocationSearch, updateSearch]
    );

    const updateFilters = React.useCallback(
        async ({ distance, queryCategories }) => {
            let res = false;

            setLoading(true);
            setError(null);
            try {
                await updateQuery({ distance, categories: queryCategories });
                res = true;
            } catch (e) {
                setError({ ts: Date.now(), message: e?.message ?? typeof e === 'string' ? e : DEFAULT_ERROR });
            }
            setLoading(false);
            return res;
        },
        [updateQuery]
    );

    const requestLocation = React.useCallback(async () => {
        if (window.navigator?.geolocation) {
            return new Promise((res) => {
                setError(false);
                setLoading(true);
                window.navigator.geolocation.getCurrentPosition(
                    (pos) => res(searchLocation(Location.Coord(pos.coords))),
                    (e) => {
                        logger.warn('Location services failed', e);
                        setError({ ts: Date.now(), message: 'Dealer.Locator.Geolocation.Failure.Message' });
                        setLoading(false);
                        res(false);
                    }
                );
            });
        }
        return false;
    }, [searchLocation]);

    const gotoLocation = React.useCallback(
        (loc, zoom) => {
            if (viewport.is.mobile) return undefined;
            return () => {
                updateMap({ openLocation: loc.id });
                ctrlRef.current?.moveToLocation(loc, zoom);
            };
        },
        [viewport, updateMap]
    );

    React.useEffect(() => {
        googleRef.current = google;
        const initialSearch = async () => {
            if (initialLoc.current) {
                searchLocation(initialLoc.current);
            } else if (!(await requestLocation())) {
                searchLocation({ lat: parseFloat(defaultLat), lng: parseFloat(defaultLng), fullAddress: defaultAddr });
            }
        };

        if (google) {
            initialSearch();
        }
    }, [defaultAddr, defaultLat, defaultLng, google, requestLocation, searchLocation]);

    const componentDidMount = React.useRef(async () => {
        updateQuery({ brandName, unit, language }, false);
    });

    React.useEffect(() => {
        initialLoc.current =
            initialLat && initialLng
                ? { lat: parseFloat(initialLat), lng: parseFloat(initialLng), fullAddress: initialAddr }
                : null;
    }, [initialLat, initialLng, initialAddr]);

    React.useLayoutEffect(() => {
        setInnerHeight(dims.height - (container.current?.getBoundingClientRect()?.top ?? 0));
    }, [dims]);

    React.useLayoutEffect(() => {
        componentDidMount.current();
    }, []);

    return (
        <div className={cx('StoreLocator d-flex', viewport.is.mobile ? 'mobile' : 'desktop')} ref={container}>
            <div className="StoreLocator__list">
                <LocatorList
                    categories={categories}
                    disclaimer={disclaimerText}
                    height={innerHeight}
                    isMobile={viewport.is.mobile}
                    mapState={mapState}
                    onClearLocation={clearLocation}
                    onGotoLocation={gotoLocation}
                    onLoadMore={loadMore}
                    onUpdateFilters={updateFilters}
                    searchState={searchState}
                >
                    <UserMessage message={error?.message} timestamp={error?.ts} type="error" />
                    {viewport.is.mobile && !searchState.location ? (
                        <LocationSearch
                            apiKey={apiKey}
                            google={googleRef.current}
                            onChange={onSearchInput}
                            onClearInput={onClearSearchInput}
                            onInput={onSearchInput}
                            onPlaceChange={searchLocation}
                            onRequestLocation={window.navigator?.geolocation ? requestLocation : null}
                            placeholder={searchPlaceholder}
                            value={searchState.input}
                        />
                    ) : null}
                </LocatorList>
            </div>
            {viewport.is.mobile ? null : (
                <div className="StoreLocator__map">
                    <LocatorMap
                        allowResearch
                        apiKey={apiKey}
                        cluster={{ radius: 100 }}
                        enableClusters
                        google={googleRef.current}
                        height={innerHeight}
                        mapState={mapState}
                        onSearchLocation={searchLocation}
                        onUpdate={updateMap}
                        ref={ctrlRef}
                        searchState={searchState}
                    >
                        <LocationSearch
                            apiKey={apiKey}
                            google={googleRef.current}
                            onChange={onSearchInput}
                            onClearInput={onClearSearchInput}
                            onInput={onSearchInput}
                            onPlaceChange={searchLocation}
                            onRequestLocation={window.navigator?.geolocation ? requestLocation : null}
                            placeholder={searchPlaceholder}
                            value={searchState.input}
                        />
                    </LocatorMap>
                </div>
            )}
            {loading ? <Loader /> : null}
        </div>
    );
}

StoreLocator.propTypes = {
    brandName: px.string,
    apiKey: px.string,
    defaultLat: px.oneOfType([px.number, px.string]),
    defaultLng: px.oneOfType([px.number, px.string]),
    defaultAddr: px.string,
    disclaimerText: px.string,
    // locationContent: px.any,
    language: px.string,
    unit: px.oneOf([UNIT.MILE, UNIT.KILOMETER]),
    categories: px.arrayOf(px.string),
    initialFilters: px.shape({ distance: px.number }),
};
