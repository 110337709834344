import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { useRoutes } from './hooks';

export function DefaultNavItem({ id, label, active, data }) {
    return (
        <a className={cx('DefaultNavItem', { active })} href={`#${id}`} disabled={data?.disabled}>
            {label}
        </a>
    );
}

DefaultNavItem.propTypes = {
    id: px.string,
    label: px.string,
    active: px.bool,
    data: px.shape({
        disabled: px.bool,
    }),
};

export default function HashRouterNav({ className, NavItem = DefaultNavItem, ...props }) {
    const routes = useRoutes();

    return (
        <div {...props} className={cx('HashNav', className)}>
            {routes.map((r, idx) => (
                <NavItem key={`router_nav_item_${r.id}`} {...r} idx={idx} />
            ))}
        </div>
    );
}

HashRouterNav.propTypes = {
    className: px.string,
    NavItem: px.elementType,
};
