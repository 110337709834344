import { useRef, useMemo, useState, useEffect } from 'react';
import { v4 } from 'uuid';
import useScript from '../useScript';
import { Url } from 'Common/utils';

function createButtons(paypal, id, value, onApprove, onReady) {
    paypal
        .Buttons({
            style: { label: 'paypal' },
            onInit: onReady,
            createOrder: (_, actions) =>
                actions.order.create({
                    purchase_units: [{ amount: { value: `${value}` } }],
                }),
            onApprove,
        })
        .render(`#${id}`);
}

export default function usePayPal({ id: idProp, options, currency, paymentAmount }) {
    const id = useRef(idProp || `paypal-buttons-${v4()}`);
    const buttonsAdded = useRef(false);
    const [loaded, setLoaded] = useState(false);
    const [token, setToken] = useState(null);

    const src = useMemo(() => {
        const url = Url.create(`https://www.paypal.com/sdk/js`)
            .addParam('client-id', options.ClientId)
            .addParam('currency', currency)
            .addParam('intent', 'authorize');

        if (options.Sandbox) url.addParam('debug', 'true');
        if (options.MerchantId) url.addParam('merchant-id', options.MerchantId);

        return url.href;
    }, [options, currency]);

    const paypal = useScript(src, { globalNamespace: 'paypal', destroyOnDismount: false, delay: 100 });

    useEffect(() => {
        if (paypal && !buttonsAdded.current) {
            createButtons(
                paypal,
                id.current,
                paymentAmount,
                (data) => setToken(data.orderID),
                () => setLoaded(true)
            );
            buttonsAdded.current = true;
        }
    }, [paypal, paymentAmount]);

    return { loaded, token };
}
