import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';

function Block({ h, w, c, p, m }) {
    return <div className={cx('FakeBlock', c)} style={{ height: h, width: w, padding: p, margin: m }}></div>;
}

Block.propTypes = {
    h: px.oneOfType([px.string, px.number]),
    w: px.oneOfType([px.string, px.number]),
    m: px.string,
    p: px.string,
    c: px.string,
};

export default function FakeCartItem({ className, hideQty = false }) {
    return (
        <div className={cx('CartItem FakeCartItem', className)}>
            <div className="CartItem__col left">
                <Block c="CartItem__gallery" w="4em" h="4em" />
                <Block c="CartItem__gallery" w="4em" h="1.5em" />
            </div>
            <div className="CartItem__col right">
                <Block c="CartItem__name" w="calc(100% - 2em)" h="3em" />

                <div className="CartItem__table mt-3">
                    <table>
                        <tbody>
                            <tr>
                                <th>
                                    <Block h="1em" w="7em" m=".25em 0" />
                                </th>
                                <td>
                                    <Block h="1em" w="6em" m=".25em 0" />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <Block h="1em" w="5em" m=".25em 0" />
                                </th>
                                <td>
                                    <Block h="1em" w="5em" m=".25em 0" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="d-flex mt-3">
                    {!hideQty ? <Block w="4em" h="2em" /> : null}
                    <div className="flex-1" />
                    <Block w="4em" h="2em" />
                </div>
            </div>
        </div>
    );
}

FakeCartItem.propTypes = {
    className: px.string,
    hideQty: px.bool,
};
