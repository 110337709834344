import { useMemo, useState, useCallback, useEffect } from 'react';
import { validateApplePay } from 'Common/features/order/api';

export const STATE = {
    STARTUP: 'STATE_STARTUP',
    UNSUPPORTED_BROWSER: 'STATE_UNSUPPORTED_BROWSER',
    NO_PAYMENT: 'STATE_NO_PAYMENT',
    SHOW_BUTTON: 'STATE_SHOW_BUTTON',
    PAYMENT_IN_PROGRESS: 'STATE_PAYMENT_IN_PROGRESS',
    PAYMENT_COMPLETE: 'STATE_PAYMENT_COMPLETE',
};

export default function useApplePay({ options, paymentAmount, systemName, marketId }) {
    const [token, setToken] = useState(undefined);
    const [state, setState] = useState(STATE.STARTUP);
    const [error, setError] = useState(false);

    const merchantIdentifier = useMemo(() => options.ApplePayMerchantIdentifier, [options]);
    const merchantName = useMemo(() => options.ApplePayDisplayName, [options]);
    const amount = useMemo(() => `${paymentAmount}`, [paymentAmount]);

    const onInitSession = useCallback(() => {
        const request = {
            countryCode: 'US',
            currencyCode: 'USD',
            supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
            merchantCapabilities: ['supports3DS'],
            total: { label: merchantName, amount: amount },
        };

        const newSession = new window.ApplePaySession(3, request);

        // when it's time to validate the merchant, we need to make a callback to the external URL to verify the domain
        // and generate one-time transaction credentials
        newSession.onvalidatemerchant = async (event) => {
            try {
                const apiResp = await validateApplePay(event.validationURL, systemName, marketId);

                if (apiResp && apiResp.Status == 'Success') {
                    const appleData = JSON.parse(apiResp.MerchantSession);

                    newSession.completeMerchantValidation(appleData);
                } else {
                    setError(true);
                    setState(STATE.SHOW_BUTTON);
                    newSession.abort();
                }
            } catch (err) {
                setError(true);
                setState(STATE.SHOW_BUTTON);
                newSession.abort();
            }
        };

        // when we select a payment, don't change the total at all but mark payment selection as complete
        newSession.onpaymentmethodselected = (/* event */) => {
            const newTotal = { type: 'final', label: merchantName, amount: amount };

            newSession.completePaymentMethodSelection({ newTotal });
        };

        // when the payment is authorized, signal to Apple Pay that we're done and update the UI to say we're done
        newSession.onpaymentauthorized = (event) => {
            setToken(JSON.stringify(event.payment.token));

            newSession.completePayment(window.ApplePaySession.STATUS_SUCCESS);

            setState(STATE.PAYMENT_COMPLETE);
        };

        // if we cancel the payment, go back to showing the button
        newSession.oncancel = () => {
            setState(STATE.SHOW_BUTTON);
        };

        // begin the payment session and change the button to a display that we're currently in progress
        newSession.begin();
        setState(STATE.PAYMENT_IN_PROGRESS);
    }, [amount, merchantName, marketId, systemName]);

    useEffect(() => {
        async function initApplePay() {
            if (window.ApplePaySession) {
                const canMakePayments = await window.ApplePaySession.canMakePaymentsWithActiveCard(merchantIdentifier);

                if (canMakePayments) {
                    setState(STATE.SHOW_BUTTON);
                } else {
                    setState(STATE.NO_PAYMENT);
                }
            } else {
                setState(STATE.UNSUPPORTED_BROWSER);
            }
        }

        initApplePay();
    }, [merchantIdentifier]);

    return { token, state, error, onInitSession };
}
