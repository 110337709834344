import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import $ from 'jquery';
import { v4 } from 'uuid';
import { Form } from 'informed';
import * as types from 'Common/types';
import { useTranslation } from 'Common/hooks';
import { Translation, Currency } from 'Common/components/localization';
import { PromoCode, GiftCard } from 'Common/components/checkout/payments';
import { Icon } from 'Common/components/ui';
import CartItemList from './CartItemList';
import FakeCartItem from './FakeCartItem';

export default function CartListing({
    id: idProp,
    className,
    forms = [],
    subtotal = 0,
    total = 0,
    loaded = false,
    checkoutPageLink,
    qtyChanges = [],
    onChangeQty,
    onDelete,
    currency,
    emptyLabel = 'Commerce.Order.Cart.EmptyCart.Label',
    gtmListValue,
    loading = false,
    includeFaux = false,
    includeTotalCount = false,
    closeButtonIcon = 'arrow-right-short',
    onClose,
    onEdit,
    inputStatusTooltip,
    priceInfo,
    children,
    splitShipments = false,
    promoProps,
    giftCardProps,
    noHeader = false,
    recapData = null,
    onBackToCustomer,
    onBackToShipping,
    clickable = false,
    cartId,
    totalQty = 0,
    ...props
}) {
    const id = React.useRef(idProp || `cart-listing-${v4()}`);
    const lblClose = useTranslation('Commerce.Order.Cart.Close.Label');
    const container = React.useRef();

    const [fauxCount, itemCount] = React.useMemo(
        () =>
            forms.reduce(
                (acc, form) =>
                    form.Shipments.reduce(
                        ([f, i], shipment) => [
                            f + (shipment.FauxLineItems?.reduce((t, o) => t + (o?.Quantity || 1), 0) || 0),
                            i + (shipment.LineItems?.reduce((t, o) => t + (o?.Quantity || 1), 0) || 0),
                        ],
                        acc
                    ),
                [0, 0]
            ),
        [forms]
    );

    const scrollToFaux = React.useCallback(() => {
        if (container.current) {
            $(container.current).animate({ scrollTop: $($('.CartListing__faux-list')[0]).position().top }, 'slow');
        }
    }, []);

    return (
        <div className={cx('CartListing', className)} ref={container} id={id.current} {...props}>
            {noHeader ? null : (
                <div className="header">
                    <h6>
                        <Translation id="Commerce.Order.Cart.Title" />
                    </h6>
                    {onClose ? (
                        <button
                            className="btn btn-close"
                            role="button"
                            type="button"
                            aria-label={lblClose}
                            onClick={onClose}
                        >
                            <Icon name={closeButtonIcon} />
                        </button>
                    ) : null}
                </div>
            )}

            {includeTotalCount && itemCount ? (
                <p className="CartListing__item-count">
                    {itemCount === 1 ? (
                        <Translation id="Commerce.Order.Checkout.Summary.ItemCount.Label.Singular" />
                    ) : (
                        <Translation
                            id="Commerce.Order.Checkout.Summary.ItemCount.Label.Plural"
                            params={{ count: itemCount }}
                        />
                    )}
                </p>
            ) : null}

            {fauxCount && includeFaux ? (
                <div className="CartListing__alert alert alert-warning" onClick={scrollToFaux}>
                    <Translation id="Commerce.Order.Cart.OutOfStock.Label" />
                </div>
            ) : null}

            {itemCount ? (
                forms.map((form) =>
                    form.Shipments.map((shipment, i) =>
                        shipment.LineItems.length ? (
                            <CartItemList
                                key={`list_${id.current}_0_${shipment.ShipmentId}`}
                                items={shipment.LineItems}
                                disabled={loading}
                                clickable={clickable}
                                qtyChanges={qtyChanges}
                                onChangeQty={onChangeQty}
                                onDeleteItem={onDelete}
                                shipmentId={shipment.ShipmentId}
                                currency={currency}
                                inputStatusTooltip={inputStatusTooltip}
                                recapData={splitShipments && recapData?.shipments ? recapData?.shipments[i] : null}
                                recapDisabled={recapData?.disabled}
                                onBackToCustomer={onBackToCustomer}
                                onBackToShipping={onBackToShipping}
                                totalQty={totalQty}
                                title={
                                    splitShipments ? (
                                        <Translation
                                            id="Commerce.Order.Checkout.Shipment.Split.Title"
                                            params={{ index: i + 1 }}
                                        />
                                    ) : (
                                        ' '
                                    )
                                }
                            />
                        ) : null
                    )
                )
            ) : !loaded ? (
                <div className="CartItemList">
                    <FakeCartItem hideQty={!onChangeQty} />
                    <FakeCartItem hideQty={!onChangeQty} />
                    <FakeCartItem hideQty={!onChangeQty} />
                </div>
            ) : (
                <div className="CartListing__alert alert alert-info">
                    <Translation id={emptyLabel} />
                </div>
            )}

            {promoProps || giftCardProps ? (
                <div className="CartListing__inputs">
                    {giftCardProps ? (
                        <Form>
                            {loaded ? (
                                <GiftCard {...giftCardProps} noLoadingIndicator />
                            ) : (
                                <div className="FakeBlock" style={{ height: '3rem', width: '100%' }} />
                            )}
                        </Form>
                    ) : null}
                    {promoProps ? (
                        <div>
                            {loaded ? (
                                <PromoCode {...promoProps} />
                            ) : (
                                <div className="FakeBlock" style={{ height: '3rem', width: '100%' }} />
                            )}
                        </div>
                    ) : null}
                </div>
            ) : null}

            {priceInfo?.length ? (
                <div className="CartListing__price">
                    {priceInfo.map(({ label, id: ID, value, children: childValues }) =>
                        childValues && ID ? (
                            <div className="dropdown" key={`summary_${id.current}_${ID}`}>
                                <div className="accordion w-100" id={`dc_${id.current}_${ID}_accordion`}>
                                    <div className="w-100" id={`dc_${id.current}_${ID}_header`}>
                                        <div
                                            role="button"
                                            data-toggle="collapse"
                                            data-target={`#dc_${id.current}_${ID}_drawer`}
                                            aria-expanded="false"
                                            aria-controls={`#dc_${id.current}_${ID}_drawer`}
                                            tabIndex="-1"
                                            className="d-flex"
                                            onClick={() =>
                                                $(`#dc_${id.current}_${ID}_header .Icon`).toggleClass('flip')
                                            }
                                        >
                                            <div className="label flex-1">
                                                <Translation id={label} />

                                                <Icon className="ml-2" name="caret-down" />
                                            </div>
                                            <div className="value">
                                                {typeof value === 'number' ? (
                                                    <Currency code={currency} amount={value} useParens="negative" />
                                                ) : value ? (
                                                    <Translation id={value} />
                                                ) : (
                                                    <span className="empty">—</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        id={`dc_${id.current}_${ID}_drawer`}
                                        className="collapse"
                                        aria-labelledby={`dc_${id.current}_${ID}_header`}
                                        data-parent={`#dc_${id.current}_${ID}_accordion`}
                                    >
                                        <div className="drawer">
                                            {childValues.map((c) => (
                                                <div
                                                    key={`dc_${id.current}_${ID}_${c.label}`}
                                                    className="price-detail d-flex"
                                                >
                                                    <div className="label flex-1">{c.label}</div>
                                                    <div className="value">
                                                        {typeof c.value === 'number' ? (
                                                            <Currency
                                                                code={currency}
                                                                amount={c.value}
                                                                useParens="negative"
                                                                truncate
                                                            />
                                                        ) : value ? (
                                                            <Translation id={c.value} />
                                                        ) : (
                                                            <span className="empty">—</span>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="d-flex" key={`summary_${label}_${value}`}>
                                <div className="label flex-1">
                                    <Translation id={label} />
                                </div>
                                <div className="value">
                                    {typeof value === 'number' ? (
                                        <Currency code={currency} amount={value} useParens="negative" />
                                    ) : value ? (
                                        <Translation id={value} />
                                    ) : (
                                        <span className="empty">—</span>
                                    )}
                                </div>
                            </div>
                        )
                    )}
                </div>
            ) : null}

            <div className="CartListing__total">
                <div className="price">
                    <div className="label">
                        <Translation
                            id={
                                priceInfo?.length
                                    ? 'Commerce.Order.Detail.Summary.Total.Label'
                                    : 'Commerce.Order.Subtotal.Label'
                            }
                        />
                    </div>
                    <div className={cx('amount', { strike: !!qtyChanges?.length })}>
                        {loaded ? (
                            <Currency code={currency} amount={total || subtotal} min={0} />
                        ) : (
                            <div
                                className="FakeBlock"
                                style={{ position: 'relative', float: ' right', height: '100%', width: '5em' }}
                            />
                        )}
                    </div>
                </div>
                {priceInfo?.length || !loaded ? null : (
                    <div className="info">
                        <Translation id="Commerce.Order.Cart.SubtotalInfo.Label" />
                    </div>
                )}
            </div>
            {checkoutPageLink ? (
                <div className="CartListing__checkout">
                    <a className="btn btn-primary" href={checkoutPageLink} disabled={!loaded || loading || !itemCount}>
                        <Translation id={loaded ? 'Commerce.Order.Button.Checkout.Label' : 'Generic.Loading.Label'} />
                    </a>
                </div>
            ) : null}

            {fauxCount && includeFaux
                ? forms.map((form) =>
                      form.Shipments.map((shipment) =>
                          shipment.FauxLineItems?.length ? (
                              <CartItemList
                                  className="CartListing__faux-list"
                                  key={`list_faux_${id.current}_0_${shipment.ShipmentId}`}
                                  items={shipment.FauxLineItems}
                                  disabled={loading}
                                  clickable={clickable}
                                  onEditItem={onEdit(shipment.ShipmentId)}
                                  onDeleteItem={onDelete}
                                  gtmListValue={gtmListValue}
                                  shipmentId={shipment.ShipmentId}
                                  currency={currency}
                                  faux
                              />
                          ) : null
                      )
                  )
                : null}

            {children ? <div className="CartListing__children">{children}</div> : null}

            {cartId ? (
                <div className="CartListing__footer">
                    <Translation id="Commerce.Order.Cart.Number.Label" />: {cartId}
                </div>
            ) : null}
        </div>
    );
}

CartListing.propTypes = {
    recapData: px.shape({
        contact: px.string,
        canEditContact: px.bool,
        disabled: px.bool,
        shipments: px.arrayOf(
            px.shape({
                digital: px.bool,
                address: px.string,
                method: px.string,
                gift: px.string,
            })
        ),
    }),
    loaded: px.bool,
    children: px.node,
    noHeader: px.bool,
    className: px.string,
    forms: px.arrayOf(types.CartForm),
    subtotal: px.number,
    total: px.number,
    onChangeQty: px.func,
    onDelete: px.func,
    clickable: px.bool,
    currency: px.string,
    qtyChanges: px.arrayOf(px.any),
    onEdit: px.func,
    onAddItemToCart: px.func,
    emptyLabel: px.string,
    gtmListValue: px.string,
    open: px.bool,
    onClose: px.func,
    checkoutPageLink: px.string,
    inputStatusTooltip: px.oneOfType([px.string, px.bool]),
    loading: px.bool,
    closeButtonIcon: px.string,
    includeFaux: px.bool,
    includeTotalCount: px.bool,
    splitShipments: px.bool,
    promoProps: px.shape(PromoCode.propTypes),
    giftCardProps: px.shape(GiftCard.propTypes),
    priceInfo: px.arrayOf(px.shape({ label: px.string, value: px.oneOfType([px.string, px.number]) })),
    onBackToCustomer: px.func,
    onBackToShipping: px.func,
    cartId: px.node,
    id: px.string,
    totalQty: px.number,
};
