import * as React from 'react';
import px from 'prop-types';
import { Translation } from '../localization';

export default function CartSummaryFooter({ cartSummaryFooterData }) {
    return (
        <div className="CartSummaryFooter">
            <div className="sub">
                <span>
                    <Translation id="Commerce.Order.Cart.Number.Label" />: {cartSummaryFooterData.orderId}
                </span>
            </div>
        </div>
    );
}

CartSummaryFooter.propTypes = {
    cartSummaryFooterData: px.shape({
        orderId: px.number,
    }),
};
