import * as React from 'react';
import px from 'prop-types';

const PIN_STYLE = { cursor: 'pointer' };
const ICON_STYLE = { tranform: 'translate(-50%, -50%)', fontSize: '37px' };

export default function LocationPin({
    children,
    onClick,
    location,
    alt = false,
    color = 'var(--dark)',
    name = location.name,
}) {
    const onKeyPress = React.useCallback((e) => (e.key === 'Enter' && onClick ? onClick() : null), [onClick]);
    const style = React.useMemo(() => ({ ...PIN_STYLE, color }), [color]);

    return (
        <div>
            {children}
            <div
                style={style}
                onClick={onClick}
                onKeyPress={onKeyPress}
                tabIndex="1"
                role="button"
                type="button"
                aria-label={name}
            >
                <i className={`fas fa-map-marker${alt ? '-alt' : ''}`} style={ICON_STYLE} />
            </div>
        </div>
    );
}

LocationPin.propTypes = {
    children: px.node,
    onClick: px.func,
    alt: px.bool,
    name: px.string,
    color: px.string,
    location: px.shape({ name: px.string }),
};
