import { useContext, useEffect, useRef, useMemo, useCallback } from 'react';
import { actions, selectors } from './duck';
import context from './context';

export function useRouter() {
    return useContext(context);
}

export function useRouteRegistry(id, label, data) {
    const dataRef = useRef(data);
    const labelRef = useRef(label);
    const idRef = useRef(id);
    const { dispatch, state } = useRouter();
    const isActive = useMemo(() => isIdActive(state, id), [state, id]);

    useEffect(
        () =>
            partialUpdate({ id, idRef, value: label, valueRef: labelRef, dispatch, action: actions.updateRouteLabel }),
        [id, label, dispatch]
    );

    useEffect(
        () => partialUpdate({ id, idRef, value: data, valueRef: dataRef, dispatch, action: actions.updateRouteData }),
        [id, data, dispatch]
    );

    useEffect(() => registerRoute({ id, idRef, labelRef, dataRef, dispatch }), [dispatch, id]);

    return isActive;
}

export function useRoute(id) {
    const { state, dispatch } = useRouter();
    const routeState = useMemo(() => selectors.getRouteById(state, id), [state, id]);
    const routeUpdate = useCallback((data, label) => dispatch(actions.updateRoute(id, data, label)), [id, dispatch]);

    return [routeState, routeUpdate];
}

export function useCurrentRoute() {
    const { state } = useRouter();

    return useMemo(() => selectors.getCurrentRoute(state), [state]);
}

export function useCurrentRouteId() {
    const { state } = useRouter();

    return useMemo(() => selectors.getCurrentRouteId(state), [state]);
}

export function useRoutes() {
    const { state } = useRouter();

    return useMemo(() => selectors.getAllRoutes(state), [state]);
}

// #region UNITS

export function partialUpdate({ id, idRef, value, valueRef, dispatch, action }) {
    if (value !== valueRef.current) {
        valueRef.current = value;
        if (idRef.current === id) {
            dispatch(action(id, value));
        }
    }
}

export function isIdActive(state, id) {
    return state.active === id;
}

export function registerRoute({ id, idRef, labelRef, dataRef, dispatch }) {
    idRef.current = id;
    dispatch(actions.addRoute(id, labelRef.current, dataRef.current));
    return () => dispatch(actions.removeRoute(id));
}

// #endregion
