import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { useTranslation } from 'Common/hooks';
import { Location } from 'Common/utils';
import { Translation } from 'Common/components/localization';
import { Icon } from 'Common/components/ui';
import { RADIUS_OPTS, UNIT, QUERY_UNIT } from 'Common/constants/locator';
import LocationResult from './LocationResult';

export default function LocatorList({
    searchState,
    height,
    className,
    onUpdateFilters,
    onClearLocation,
    onLoadMore,
    onGotoLocation,
    isMobile,
    children,
    categories,
}) {
    const unitTranslationId = React.useMemo(
        () => `Dealer.Locator.DistanceUnit.${UNIT[searchState.query.LocationFilter.DistanceUnits]}.Label`,
        [searchState.query.LocationFilter]
    );

    const translatedUnit = useTranslation(unitTranslationId);
    const distanceParams = React.useMemo(
        () => ({ amount: searchState.query.LocationFilter.Distance, unit: translatedUnit }),
        [searchState.query.LocationFilter, translatedUnit]
    );

    const translatedDistance = useTranslation('Dealer.Locator.Filter.Distance.Label', distanceParams);
    const lblClear = useTranslation('Dealer.Locator.Filter.Clear.Label');
    const style = React.useMemo(
        () => (isMobile ? { height: 'auto', maxHeight: 'initial' } : { height, maxHeight: height }),
        [isMobile, height]
    );

    const pageParams = React.useMemo(() => {
        if (onLoadMore && searchState.response.TotalPages > searchState.response.Page) {
            const { TotalPages: totalPages, TotalItems: total, Page: page, PageSize: pageSize } = searchState.response;
            const from = page * pageSize + 1;
            const to = Math.min(total, from + pageSize);

            return {
                totalPages,
                total,
                page,
                pageSize,
                from,
                to,
                next: { from, to },
            };
        }
        return null;
    }, [searchState.response, onLoadMore]);

    const onClearCategories = React.useCallback(() => {
        return onUpdateFilters({ queryCategories: [] });
    }, [onUpdateFilters]);

    const onUpdateCategories = React.useCallback(
        (category) => {
            if (searchState.query.CategoriesFilter.includes(category)) {
                return onClearCategories();
            }
            return onUpdateFilters({ queryCategories: [category] });
        },
        [searchState, onUpdateFilters, onClearCategories]
    );

    return (
        <div className={cx('LocatorList', className)} style={style}>
            {children}
            {categories?.length > 0 || searchState.location ? (
                <div className={cx('LocatorList__filters', className)}>
                    <h2>
                        <Translation id="Dealer.Locator.Filter.FilterBy.Label" />
                    </h2>
                    {categories?.length > 0 ? (
                        <div className="LocatorList__filters__radius-select">
                            <div>
                                <h6>
                                    <Translation id="Dealer.Locator.Filter.CategorySelector.Label" />
                                </h6>
                            </div>
                            <div className="d-flex">
                                {searchState.query.CategoriesFilter.length > 0 ? (
                                    <a
                                        className="LocatorList__filters__radius-clear"
                                        onClick={() => onClearCategories()}
                                        role={'button'}
                                        title={lblClear}
                                        aria-label={lblClear}
                                    >
                                        <Translation id="Dealer.Locator.Filter.ResetCategories.Label" />
                                        <Icon name="times-outlined" />
                                    </a>
                                ) : null}
                                {categories.map((cat) => (
                                    <button
                                        key={`rad-opt-${cat}`}
                                        className={cx(
                                            'LocatorList__filters__radius-select__button',
                                            searchState.query.CategoriesFilter.includes(cat)
                                                ? 'LocatorList__filters__radius-select__button--active'
                                                : 'LocatorList__filters__radius-select__button--inactive'
                                        )}
                                        onClick={() => onUpdateCategories(cat)}
                                    >
                                        {cat}
                                    </button>
                                ))}
                            </div>
                        </div>
                    ) : null}
                    {searchState.location ? (
                        <>
                            <div className="LocatorList__filters__radius-select">
                                <div>
                                    <h6>
                                        <Translation id="Dealer.Locator.Filter.RadiusSelector.Label" />
                                    </h6>
                                </div>
                                <div className="d-flex">
                                    {RADIUS_OPTS.map((rad) => (
                                        <button
                                            key={`rad-opt-${rad}`}
                                            className={cx(
                                                'LocatorList__filters__radius-select__button',
                                                searchState.query.LocationFilter.Distance === rad
                                                    ? 'LocatorList__filters__radius-select__button--active'
                                                    : 'LocatorList__filters__radius-select__button--inactive'
                                            )}
                                            onClick={() => onUpdateFilters({ distance: rad })}
                                        >
                                            <Translation
                                                id="Dealer.Locator.Filter.Distance.Label"
                                                params={{ amount: rad, unit: translatedUnit }}
                                            />
                                        </button>
                                    ))}
                                </div>
                            </div>
                            <div className="LocatorList__filters__current-location">
                                <button
                                    className="btn btn-sm btn-icon"
                                    onClick={onClearLocation}
                                    title={lblClear}
                                    aria-label={lblClear}
                                >
                                    <i className="fas fa-times" />
                                </button>
                                <p className="paragraph-2">
                                    {searchState.location.fullAddress || searchState.location.address ? (
                                        <Translation
                                            id="Dealer.Locator.Filter.ActiveFilter.Address.Label"
                                            params={{
                                                distance: translatedDistance,
                                                address:
                                                    searchState.location.fullAddress || searchState.location.address,
                                            }}
                                        />
                                    ) : (
                                        <Translation
                                            id="Dealer.Locator.Filter.ActiveFilter.GPS.Label"
                                            params={Location.truncateCoord(searchState.location, 4)}
                                        />
                                    )}
                                </p>
                            </div>
                        </>
                    ) : null}
                </div>
            ) : null}
            <div className="LocatorList__divider">
                <h4>
                    <Translation id="Dealer.Locator.Filter.Results.Label" />
                </h4>
            </div>
            <div className="LocatorList__locations">
                <ul>
                    {!searchState.response.Locations.length ? (
                        <li className="no-results">
                            <p className="mt-2">
                                <Translation id="Dealer.Locator.NoResults.Label" />
                            </p>
                        </li>
                    ) : (
                        <>
                            {searchState.response.Locations.map((loc) => (
                                <LocationResult
                                    location={loc}
                                    key={loc.id}
                                    onGotoLocation={onGotoLocation(loc, 18)}
                                    showCategories={categories?.length > 0}
                                />
                            ))}
                            {pageParams ? (
                                <li className="paging">
                                    <button className="btn btn-sm" onClick={onLoadMore}>
                                        <span>
                                            <i className="fas fa-ellipsis-h mr-1" />
                                            <Translation id="Commerce.CatalogNode.Grid.LoadMore" params={pageParams} />
                                        </span>
                                    </button>
                                </li>
                            ) : null}
                        </>
                    )}
                </ul>
            </div>
        </div>
    );
}

LocatorList.propTypes = {
    height: px.number,
    className: px.string,
    onGotoLocation: px.func,
    onClearLocation: px.func,
    onLoadMore: px.func,
    onUpdateFilters: px.func,
    isMobile: px.bool,
    children: px.node,
    categories: px.arrayOf(px.string),
    searchState: px.shape({
        location: px.shape({
            lat: px.number,
            lng: px.number,
            address: px.string,
            fullAddress: px.string,
        }),
        query: px.shape({
            CategoriesFilter: px.arrayOf(px.string),
            LocationFilter: px.shape({
                Distance: px.number,
                DistanceUnits: px.oneOf([QUERY_UNIT.MILE, QUERY_UNIT.KILOMETER]),
            }),
        }),
        response: px.shape({
            TotalPages: px.number,
            Page: px.number,
            PageSize: px.number,
            PageItems: px.number,
            TotalItems: px.number,
            Locations: px.arrayOf(
                px.shape({
                    id: px.oneOfType([px.string, px.number]),
                    name: px.string,
                    address1: px.string,
                    address2: px.string,
                    phone: px.string,
                    website: px.string,
                    directions: px.string,
                    categories: px.arrayOf(px.string),
                })
            ),
        }),
    }),
};
