import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { CatalogSort as Sort } from 'Common/components/catalog';
import { useAction, useTranslation } from 'Common/hooks';
import { catalog } from 'Common/features';

const SORT_OPTIONS = [{ Value: 256, Text: 'Relevance' }];

function convertSortOptions(options = []) {
    return options.map((p) => ({
        value: p.value || p.Value,
        label: p.label || p.Text || p.value || p.Value,
    }));
}

export default function CatalogSort({ sortOptions = SORT_OPTIONS }) {
    const sortBy = useTranslation('Commerce.CatalogNode.Filters.SortBy');
    const hasChanged = useSelector(catalog.selectors.hasChanged);
    const pageInfo = useSelector(catalog.selectors.getPageInfo);
    const updateSort = useAction(catalog.actions.updateSortOrder);
    const sortOpts = React.useMemo(() => convertSortOptions(sortOptions), [sortOptions]);
    const onFetchItems = useAction(catalog.actions.fetchItems);

    React.useEffect(() => {
        if (hasChanged) {
            onFetchItems();
        }
    }, [hasChanged, onFetchItems]);

    const onUpdateSort = React.useCallback(
        (value) => {
            updateSort(value);
        },
        [updateSort]
    );

    return (
        <Sort
            className="mx-1"
            title={sortOpts.find((o) => o.value === pageInfo.sortValue)?.label ?? sortBy}
            selectedValues={[pageInfo.sortValue]}
            options={sortOpts}
            onSelect={onUpdateSort}
        />
    );
}
CatalogSort.propTypes = {
    sortOptions: px.string,
};
