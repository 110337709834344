import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';

export default function ClusterPin({ className, location, onClick }) {
    const size = React.useMemo(
        () => (location.point_count > 50 ? 'large' : location.point_count > 25 ? 'medium' : 'small'),
        [location.point_count]
    );

    const onKeyPress = React.useCallback((e) => (e.key === 'Enter' ? onClick() : null), [onClick]);

    return (
        <div
            className={cx('ClusterPin', size, className)}
            role="button"
            tabIndex="1"
            aria-label={location.point_count}
            onClick={onClick}
            onKeyPress={onKeyPress}
        >
            <div className="pointCount">{location.point_count}</div>
        </div>
    );
}

ClusterPin.propTypes = {
    className: px.string,
    onClick: px.func,
    location: px.shape({
        point_count: px.number,
        cluster_id: px.oneOfType([px.string, px.number]),
        lat: px.number,
        lng: px.number,
    }),
};
