import * as React from 'react';
import px from 'prop-types';
import { useFieldState } from 'informed';
import { usePaymentToken } from 'Common/hooks/payment';
import useApplePay, { STATE } from 'Common/hooks/payment/useApplePay';
import { Spinner } from 'Common/components/ui';
import { Translation } from 'Common/components/localization';
import { PAYMENT } from 'Common/constants/fields';

export default function ApplePay({ options, scope, paymentAmount, marketId, onChange }) {
    const { value: systemName } = useFieldState(scope ? `${scope}.${PAYMENT.systemName}` : PAYMENT.systemName);
    const { state, token, error, onInitSession } = useApplePay({ options, paymentAmount, systemName, marketId });
    const lastToken = React.useRef(token);

    usePaymentToken(token, scope);

    React.useEffect(() => {
        if (token !== lastToken.current) {
            lastToken.current = token;
            if (token && onChange) onChange();
        }
    }, [token, onChange]);

    return state === STATE.STARTUP ? (
        <div className="ApplePayLoading">
            <Spinner />
        </div>
    ) : state === STATE.UNSUPPORTED_BROWSER ? (
        <div className="alert alert-danger">
            <Translation id="Commerce.Order.Checkout.PaymentMethod.ApplePay.UnsupportedBrowser" />
        </div>
    ) : state === STATE.NO_PAYMENT ? (
        <div className="alert alert-danger">
            <Translation id="Commerce.Order.Checkout.PaymentMethod.ApplePay.CannotMakePayments" />
        </div>
    ) : state === STATE.SHOW_BUTTON ? (
        <>
            {error && (
                <div className="alert alert-danger">
                    <Translation id="Commerce.Order.Checkout.PaymentMethod.ApplePay.SomethingWentWrong" />
                </div>
            )}
            <div className="apple-pay-button apple-pay-button-black" onClick={onInitSession}></div>
        </>
    ) : state === STATE.PAYMENT_IN_PROGRESS ? (
        <div className="alert alert-info">
            <Translation id="Commerce.Order.Checkout.PaymentMethod.ApplePay.PaymentInProgress" />
        </div>
    ) : state === STATE.PAYMENT_COMPLETE ? (
        <div className="alert alert-success">
            <Translation id="Commerce.Order.Checkout.PaymentMethod.ApplePay.PaymentComplete" />
        </div>
    ) : null;
}

ApplePay.propTypes = {
    onChange: px.func,
    scope: px.string,
    options: px.shape({
        ApplePayMerchantIdentifier: px.string.isRequired,
        ApplePayDisplayName: px.string.isRequired,
    }),
    marketId: px.string.isRequired,
    paymentAmount: px.number.isRequired,
};
