import * as React from 'react';
import px from 'prop-types';
import GooglePayButton from '@google-pay/button-react';
import { useGooglePay, usePaymentToken } from 'Common/hooks/payment';
import { Translation } from 'Common/components/localization';

export default function GooglePay({ scope, paymentAmount, currency, options, onChange }) {
    const { paymentRequest, onLoadPaymentData, onError, token, error } = useGooglePay({
        options,
        paymentAmount,
        currency,
    });

    const lastToken = React.useRef(token);

    usePaymentToken(token, scope);

    React.useEffect(() => {
        if (token !== lastToken.current) {
            lastToken.current = token;
            if (token && onChange) onChange();
        }
    }, [token, onChange]);

    return (
        <>
            {error ? (
                <div className="alert alert-danger">
                    <Translation id="Commerce.Order.Checkout.PaymentMethod.GooglePay.Error" />
                </div>
            ) : null}

            <GooglePayButton
                environment={options.TestMode == 'True' ? 'TEST' : 'PRODUCTION'}
                paymentRequest={paymentRequest}
                // onPaymentAuthorized={onPaymentAuthorized}
                onLoadPaymentData={onLoadPaymentData}
                onError={onError}
            />
        </>
    );
}

GooglePay.propTypes = {
    paymentAmount: px.number,
    scope: px.string,
    currency: px.string,
    onChange: px.func,
    options: px.shape({
        TestMode: px.oneOf(['True', 'False']),
        EnabledCardNetworks: px.string.isRequired,
        MerchantId: px.string.isRequired,
        MerchantName: px.string.isRequired,
        GatewayMerchantId: px.string.isRequired,
    }).isRequired,
};
