import px from 'prop-types';
import * as React from 'react';
import cx from 'classnames';
import { Translation } from 'Common/components/localization';

export default function CatalogSort({
    className,
    title = '',
    selectedValues = [],
    options = [],
    onSelect,
    anchor = 'left',
    disabled = false,
    autoClose = true,
}) {
    const startExpandedRef = React.useRef(null);
    const [expanded, setExpanded] = React.useState(startExpandedRef.current);
    const clickedRef = React.useRef(false);

    const onClick = React.useCallback(
        (value) => (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (onSelect) onSelect(value);
            if (autoClose) setExpanded(false);
        },
        [onSelect, autoClose]
    );

    const toggleFilter = React.useCallback(() => {
        clickedRef.current = !expanded;
        setExpanded(clickedRef.current);
    }, [expanded]);

    React.useEffect(() => {
        function onWindowClick() {
            if (expanded && !clickedRef.current) {
                setExpanded(false);
            }

            clickedRef.current = false;
        }

        window.addEventListener('click', onWindowClick);
        return () => window.removeEventListener('click', onWindowClick);
    }, [expanded]);

    return (
        <div className="col-12 CatalogSort">
            <div className="d-flex flex-row justify-content-end">
                <label htmlFor="sort-order" className="CatalogSort__label mr-2 align-self-center my-0">
                    <Translation id="Commerce.CatalogNode.Filters.SortBy" />
                </label>
                <div className={cx('CatalogSort__options', className, { expanded })}>
                    <button
                        className="btn btn-sm"
                        type="button"
                        role="dropdown"
                        aria-expanded={expanded}
                        aria-controls="dropdown-selector"
                        onClick={toggleFilter}
                    >
                        <span>{title}</span>
                        <i className={`fa fa-chevron-${expanded ? 'up' : 'down'}`} aria-hidden="true" />
                    </button>
                    {expanded ? (
                        <div className="CatalogSort__options-list" style={{ [anchor]: 0 }}>
                            {options.map((option) => (
                                <button
                                    key={option?.value || option}
                                    type="button"
                                    role="button"
                                    className={cx('btn btn-sm', {
                                        disabled: disabled === true || (disabled && disabled[option?.value || option]),
                                        selected: selectedValues.includes(option?.value || option),
                                    })}
                                    onClick={onClick(option?.value || option)}
                                >
                                    <span>{option?.label || option}</span>
                                </button>
                            ))}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

CatalogSort.propTypes = {
    className: px.string,
    title: px.node,
    selectedValues: px.arrayOf(px.oneOfType([px.string, px.number])),
    options: px.arrayOf(px.any),
    onSelect: px.func,
    anchor: px.string,
    disabled: px.oneOfType([px.bool, px.objectOf(px.bool)]),
    autoClose: px.bool,
};
