import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { v4 } from 'uuid';
import get from 'lodash.get';
import { Relevant, useFormApi, useFieldState } from 'informed';
import { CreditCard } from 'Common/utils';
import * as types from 'Common/types';
import { useExpDateFormat } from 'Common/hooks/payment';
import { PAYMENT, EMPTY_PAYMENT } from 'Common/constants/fields';
import { Translation } from 'Common/components/localization';
import Field from '../Field';
import SavedPaymentSelector from './SavedPaymentSelector';
import { CurrentPaymentDisplay } from './display';
import * as Providers from './types';

const CANNOT_RESET_PAYMENT = {
    ApplePay: true,
};

export default function PaymentForm({
    id: idProp,
    className,
    scope = '',
    useAlternateForms = false,
    useConnectedForms = false,
    method,
    selectorClass,
    formClass,
    initialPayment,
    cardSeparator = '-',
    defaultShipment,
    onChange,
    disabled = false,
    ...props
}) {
    const id = React.useRef(idProp || `PaymentForm-${v4()}`);
    const scopeStr = React.useMemo(() => (scope ? `${scope}.` : ''), [scope]);
    const formApi = useFormApi();
    const { value: token } = useFieldState(`${scopeStr}${PAYMENT.token}`);
    const formatter = useExpDateFormat();
    const canResetPayment = React.useMemo(() => !CANNOT_RESET_PAYMENT[method[PAYMENT.paymentType]], [method]);
    const Provider = React.useMemo(() => Providers[method[PAYMENT.paymentType]], [method]);

    const loadPayment = React.useCallback(
        (payment = EMPTY_PAYMENT) => {
            const next = CreditCard.paymentToForm({
                payment,
                method,
                formatter,
                defaultShipment,
                savePayment: !!payment[PAYMENT.token],
                homogenize: true,
            });

            formApi.setValue(`${scopeStr}${PAYMENT.token}`, next[PAYMENT.token]);
            setTimeout(() => {
                if (scope) formApi.setValue(scope, next);
                else formApi.setValues(next);
                onChange();
            });
        },
        [formApi, scope, scopeStr, method, defaultShipment, formatter, onChange]
    );

    const noToken = React.useCallback(
        ({ formState: { values } = {} } = {}) => !get(values, `${scopeStr}${PAYMENT.token}`),
        [scopeStr]
    );

    const hasToken = React.useCallback(
        ({ formState: { values } = {} } = {}) => !!get(values, `${scopeStr}${PAYMENT.token}`),
        [scopeStr]
    );

    const clearPayment = React.useCallback(() => {
        loadPayment();
        onChange();
    }, [loadPayment, onChange]);

    // This is really dumb, but without it, selecting saved payments from a new payment form will not work.
    useFieldState(scope);

    return (
        <div className={cx('PaymentForm', method[PAYMENT.paymentType], className)} id={id.current}>
            {method.SavedPaymentInfo?.length ? (
                <SavedPaymentSelector
                    className={selectorClass}
                    payments={method.SavedPaymentInfo}
                    onNewPayment={clearPayment}
                    onLoadPayment={loadPayment}
                    scope={scope}
                    paymentType={method[PAYMENT.paymentType]}
                    defaultExpanded={!token}
                    disabled={disabled}
                    cardSeparator={cardSeparator}
                />
            ) : token && canResetPayment ? (
                <button className="btn-clear btn btn-outline-primary" onClick={clearPayment} disabled={disabled}>
                    <span>
                        <Translation id="Commerce.Account.AddPayment.Label" />
                    </span>
                </button>
            ) : null}

            <div className={cx('FormContainer', formClass)}>
                <Relevant when={noToken} relevanceWhen={[`${scopeStr}${PAYMENT.token}`, `${scope}`]}>
                    <Provider
                        alternate={useAlternateForms}
                        connected={useConnectedForms}
                        options={method.Options}
                        scope={scope}
                        parentId={id.current}
                        onChange={onChange}
                        {...props}
                    />
                </Relevant>

                <Relevant when={hasToken} relevanceWhen={[`${scopeStr}${PAYMENT.token}`, `${scope}`]}>
                    <Field hidden name={`${scopeStr}${PAYMENT.cardNumber}`} />
                    <Field hidden name={`${scopeStr}${PAYMENT.cardCvv}`} />
                    <Field hidden name={`${scopeStr}${PAYMENT.expMonth}`} />
                    <Field hidden name={`${scopeStr}${PAYMENT.expYear}`} />
                    <Field hidden name={`${scopeStr}${PAYMENT.cardType}`} />
                    <Field hidden name={`${scopeStr}${PAYMENT.merchantGuid}`} />
                    <Field hidden name={`${scopeStr}${PAYMENT.customerName}`} />
                    <CurrentPaymentDisplay type={method[PAYMENT.paymentType]} separator={cardSeparator} scope={scope} />
                </Relevant>
            </div>
        </div>
    );
}

PaymentForm.propTypes = {
    id: px.string,
    scope: px.string,
    useAlternateForms: px.bool,
    useConnectedForms: px.bool,
    defaultShipment: types.Shipment,
    method: types.PaymentMethod.isRequired,
    className: px.string,
    selectorClass: px.string,
    initialPayment: px.shape({ token: px.string, type: px.string }),
    formClass: px.string,
    disabled: px.bool,
    onChange: px.func,
    cardSeparator: px.string,
};
