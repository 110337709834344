import { UNIT } from './constants';

export function stopPropagation(e) {
    e.stopPropagation();
}

export function mapLocationData(locations) {
    return (
        locations?.map((l) => ({
            lat: l.Lat,
            lng: l.Lng,
            id: l.Id,
            name: l.Name,
            address1: l.Extended.Address.Line1,
            address2: `${l.Extended.Address.Locality}, ${l.Extended.Address.RegionCode} ${l.Extended.Address.PostalCode}`,
            phone: l.Extended.Phone,
            website: l.Extended.Website,
            directions: `https://www.google.com/maps/dir/?${new URLSearchParams({
                api: 1,
                destination: `${l.Extended.Address.Line1}+${l.Extended.Address.Locality},+${l.Extended.Address.StateCode}+${l.Extended.Address.PostalCode}`,
            }).toString()}`,
        })) || []
    );
}

export function getDistance(lat1, lon1, lat2, lon2, unit = UNIT.MILE) {
    if (lat1 === lat2 && lon1 === lon2) return 0;

    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

    if (dist > 1) dist = 1;
    dist = Math.acos(dist);
    dist *= 180 / Math.PI;
    dist *= 60 * 1.1515;
    if (unit === UNIT.MILE) return dist;
    return dist * 1.609344;
}
