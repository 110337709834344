import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { v4 } from 'uuid';
import * as types from 'Common/types';
import { useTranslation } from 'Common/hooks';
import { Translation } from 'Common/components/localization';
import { Icon } from 'Common/components/ui';
import CartItem from './CartItem';

export default function VariantSelector({
    className,
    currency,
    loading = false,
    onClose,
    closeButtonIcon = 'arrow-right-short',
    onChangeVariant,
    changeVariant,
    gtmListValue,
    inputStatusTooltip,
    totalQty = 0,
    ...props
}) {
    const id = React.useRef(`variant-selector-${v4()}`);
    const lblClose = useTranslation('Commerce.Order.Button.BackToCart.Label');
    const lastChangeVariant = React.useRef(changeVariant);
    const [replacementQuantities, setReplacementQuantites] = React.useState({});

    const onReplacementQty = React.useCallback(
        (code) => (num) => {
            setReplacementQuantites({ ...replacementQuantities, [code]: num });
        },
        [replacementQuantities]
    );

    React.useEffect(() => {
        if (changeVariant !== lastChangeVariant.current) {
            lastChangeVariant.current = changeVariant;
            setReplacementQuantites({});
        }
    }, [changeVariant]);

    return (
        <div className={cx('VariantSelector', className)} id={id} {...props}>
            <div className="header">
                <h6>
                    <Translation id="Commerce.Order.OutOfStock.ChooseReplacement.Label" />
                </h6>
                {onClose ? (
                    <button className="btn btn-close" role="button" aria-label={lblClose} onClick={onClose}>
                        <Icon name={closeButtonIcon} />
                    </button>
                ) : null}
            </div>
            {changeVariant?.productInfo?.Children?.map((c) => (
                <CartItem
                    key={`variant_choice_${id.current}_${c.Code}`}
                    qty={replacementQuantities[c.Code]}
                    onChangeQty={onReplacementQty(c.Code)}
                    item={c}
                    onAdd={onChangeVariant}
                    disabled={loading}
                    currency={currency}
                    gtmListValue={gtmListValue}
                    inputStatusTooltip={inputStatusTooltip}
                    totalQty={totalQty}
                />
            ))}
        </div>
    );
}

VariantSelector.propTypes = {
    id: px.string,
    className: px.string,
    currency: px.string,
    gtmListValue: px.string,
    onClose: px.func,
    loading: px.bool,
    onChangeVariant: px.func,
    inputStatusTooltip: px.oneOfType([px.string, px.bool]),
    closeButtonIcon: px.string,
    changeVariant: px.shape({
        lineItem: types.CartLineItem,
        productInfo: types.Product,
        shipmentId: px.oneOfType([px.string, px.number]),
    }),
    totalQty: px.number,
};
