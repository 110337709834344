import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { useAmazonPay, usePaymentToken } from 'Common/hooks/payment';
import { Spinner } from 'Common/components/ui';

export default function AmazonPay({ parentId, scope, options, onChange }) {
    const { isLoading, isLoggedIn, orderId, btnId } = useAmazonPay(parentId, options);
    const lastToken = React.useRef(orderId);

    usePaymentToken(orderId, scope);

    React.useEffect(() => {
        if (orderId !== lastToken.current) {
            lastToken.current = orderId;
            if (orderId && onChange) onChange();
        }
    }, [orderId, onChange]);

    return isLoading ? (
        <div className="AmazonLoading">
            <Spinner />
        </div>
    ) : (
        <div id={btnId} className={cx('AmazonPayButtonContainer', isLoggedIn ? 'd-none' : 'd-block')} />
    );
}

AmazonPay.propTypes = {
    parentId: px.string.isRequired,
    scope: px.string,
    onChange: px.func,
    options: px.shape({
        ClientId: px.string.isRequired,
        Env: px.string.isRequired,
        SellerId: px.string.isRequired,
    }).isRequired,
};
