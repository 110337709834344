import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { useAction, useTranslation } from 'Common/hooks';
import { customer } from '~features';
import { Translation } from 'Common/components/localization';
import { Modal } from 'Common/components/ui';

const ACTION = {
    delete: 'delete',
    none: null,
};

export default function NotificationSubscriptionUnsubscribeButton({ id }) {
    const closeLabel = useTranslation('Commerce.Account.CancelLabel');
    const confirmLabel = useTranslation('Commerce.Account.ConfirmUnsubscribeLabel');
    const [working, setWorking] = React.useState(false);
    const [selected, setSelected] = React.useState({ action: null });
    const notificationSubscriptionUnsubscribe = useAction(customer.actions.unsubscribeNotificationSubscriptionById);

    const onNotificationSubscriptionUnsubscribe = React.useCallback(async () => {
        setWorking(true);
        await notificationSubscriptionUnsubscribe(id);
        setSelected({ action: null });
        setWorking(false);
    }, [id, notificationSubscriptionUnsubscribe]);

    const selectNotificationSubscription = React.useCallback(
        (action = null) => () => {
            setSelected({ action });
        },
        []
    );

    const onCancel = React.useCallback(() => {
        setSelected({ action: null });
        setWorking(false);
    }, []);

    return (
        <div className={cx('NotificationSubscriptionUnsubscribeButton')}>
            <button
                onClick={selectNotificationSubscription(ACTION.delete)}
                className="btn btn-primary btn-sm"
                disabled={working || selected.action}
                type="button"
            >
                <Translation id="Commerce.Account.NotificationSubscription.Unsubscribe" />
            </button>
            {selected.action === ACTION.delete ? (
                <Modal
                    onConfirm={onNotificationSubscriptionUnsubscribe}
                    onClose={onCancel}
                    closeLabel={closeLabel}
                    confirmLabel={confirmLabel}
                    header={<Translation id="Commerce.Account.NotificationSubscriptionModalLabel" />}
                    id="deleteNotificationSubscriptionModal"
                    showModal={true}
                />
            ) : null}
        </div>
    );
}

NotificationSubscriptionUnsubscribeButton.propTypes = {
    id: px.string,
};
