/* eslint-disable react/prop-types */
import React from 'react';
import cx from 'classnames';
import GoogleMap, { fitBounds } from 'google-map-react';
import Script from 'react-load-script';
import { Location } from 'Common/utils';
import { UserMessage } from 'Common/components/ui';
import LocationPin from './LocationPin';
import ClusterPin from './ClusterPin';
import MapInfo from './MapInfo';
import isEqual from 'fast-deep-equal';

const RESEARCH_TOLERANCE_MILES = 2;

const strToFixed = (str, dec) => {
    return parseFloat(parseFloat(str).toFixed(dec));
};

const Hash = {
    create(locations, bounds, cluster) {
        const base = `b:${bounds.ne.lat},${bounds.ne.lng},${bounds.sw.lat},${bounds.sw.lng};`;
        const c = cluster
            ? `c:${cluster.radius},${cluster.extend},${cluster.nodeSize},${cluster.minZoom},${cluster.maxZoom};`
            : 'c:0';

        return { locations, key: `${base};${c};` };
    },

    equals(a, b) {
        return a && b && a.key === b.key && a.locations === b.locations;
    },
};

export default React.forwardRef(function LocatorMap(
    {
        allowResearch = false,
        apiKey,
        children,
        className,
        cluster,
        ClusterPinComponent = cluster?.component ?? ClusterPin,
        enableClusters = false,
        gestureHandling = 'cooperative',
        google,
        height = '100%',
        MapInfoComponent = MapInfo,
        mapOptions,
        mapState,
        onMapLoaded,
        onSearchLocation,
        onTilesRendered,
        onUpdate,
        pin,
        PinComponent = pin?.component ?? LocationPin,
        region = 'us',
        searchState,
        width = '100%',
    },
    ctrlRef
) {
    const lastLocation = React.useRef();
    const container = React.useRef();
    const googleMapRef = React.useRef();
    const mapApiRef = React.useRef();
    const controllerRef = React.useRef();
    const loadingRef = React.useRef(0);
    const [loaded, setLoaded] = React.useState(false);
    const [showResearch, setShowResearch] = React.useState(-1);
    const [culledLocations, setCulledLocations] = React.useState([]);
    const locationHash = React.useRef(null);
    const googleRef = React.useRef(null);
    const onMapLoadedRef = React.useRef(onMapLoaded);

    const onMapChanged = React.useCallback(
        (mapProps) => {
            if (mapProps && loaded) {
                const bounds = Location.convertBounds(mapProps.bounds, 'offset');
                const center = Location.Coord(mapProps.center, 0, 'offset');

                if (allowResearch) {
                    setShowResearch(
                        !searchState.location ||
                            Location.getDistanceBetweenCoords(mapProps.center, searchState.location) >
                                RESEARCH_TOLERANCE_MILES
                            ? Date.now()
                            : -1
                    );
                }
                onUpdate({ bounds, center });
            }
        },
        [loaded, onUpdate, allowResearch, searchState.location]
    );

    const changeLocation = React.useCallback(
        async (coordsOrPlace) => {
            setShowResearch(-1);
            if (onSearchLocation) onSearchLocation(coordsOrPlace);
        },
        [onSearchLocation]
    );

    const onResearch = React.useCallback(() => {
        if (allowResearch && showResearch > 0 && mapState.center) {
            changeLocation(mapState.center);
        }
    }, [allowResearch, showResearch, changeLocation, mapState.center]);

    const toggleLocation = React.useCallback(
        (id) => onUpdate({ openLocation: id === mapState.openLocation ? null : id }),
        [onUpdate, mapState.openLocation]
    );

    const closeLocation = React.useCallback(() => onUpdate({ openLocation: null }), [onUpdate]);

    const getViewportWithBounds = React.useCallback((bounds) => {
        const newBounds = {
            ne: Location.Coord(bounds.getNorthEast().lat(), bounds.getNorthEast().lng()),
            sw: Location.Coord(bounds.getSouthWest().lat(), bounds.getSouthWest().lng()),
        };

        let size = {};

        if (container.current) {
            size = {
                width: container.current.offsetWidth,
                height: container.current.offsetHeight,
            };
        }

        return fitBounds(newBounds, size);
    }, []);

    const getLocationsViewportPartial = React.useCallback(
        (locs = [], bounds = null) => {
            let b = bounds;

            if (locs.length > 1 && googleRef.current) {
                if (!b) {
                    b = new googleRef.current.maps.LatLngBounds();

                    locs.forEach((loc) => {
                        bounds.extend(new googleRef.current.maps.LatLng(loc.lat, loc.lng));
                    });
                }

                return getViewportWithBounds(b);
            }

            if (locs.length === 1) {
                return { center: Location.Coord(locs[0].lat, locs[0].lng) };
            }

            return {};
        },
        [getViewportWithBounds]
    );

    const getCurrentArea = React.useCallback(
        (locs = [], zoomLevel = googleMapRef.current?.props?.zoom ?? mapState.zoom) => {
            if (!googleRef.current) return mapState;
            const bounds = new googleRef.current.maps.LatLngBounds();

            locs.forEach((loc) => {
                bounds.extend(new googleRef.current.maps.LatLng(loc.lat, loc.lng));
            });

            const { center = mapState.center, zoom = zoomLevel } = getLocationsViewportPartial(locs, bounds);
            const size = {
                width: container.current.offsetWidth,
                height: container.current.offsetHeight,
            };

            const newBounds = {
                ne: {
                    lat: bounds.getNorthEast().lat(),
                    lng: bounds.getNorthEast().lng(),
                },
                nw: {
                    lat: bounds.getNorthEast().lat(),
                    lng: bounds.getSouthWest().lng(),
                },
                se: {
                    lat: bounds.getSouthWest().lat(),
                    lng: bounds.getNorthEast().lng(),
                },
                sw: {
                    lat: bounds.getSouthWest().lat(),
                    lng: bounds.getSouthWest().lng(),
                },
            };

            return { center, zoom, size, bounds: newBounds };
        },
        [getLocationsViewportPartial, mapState]
    );

    const onGoogleMapApiLoad = React.useCallback(({ map }) => {
        mapApiRef.current = map;
        loadingRef.current += 0.5;
        setLoaded(loadingRef.current === 1);
    }, []);

    React.useEffect(() => {
        googleRef.current = google;
    }, [google]);

    React.useEffect(() => {
        if (
            !lastLocation.current ||
            !searchState.location ||
            Location.getDistanceBetweenCoords(lastLocation.current, searchState.location) > 0
        ) {
            lastLocation.current = searchState.location;
            if (searchState.location) {
                onUpdate({ center: searchState.location, zoom: 12 });
                setShowResearch(-1);
            } else {
                const currentArea = getCurrentArea(searchState.response.Locations);

                if (!isEqual(currentArea, mapState)) {
                    onUpdate(currentArea);
                }
            }
        }
    }, [searchState.response.Locations, searchState.location, mapState, onUpdate, getCurrentArea]);

    React.useEffect(() => {
        controllerRef.current = {
            moveToLocation(loc, zoomLevel) {
                onUpdate(getCurrentArea([loc], zoomLevel));
            },
        };
    }, [onUpdate, getCurrentArea]);

    React.useEffect(() => {
        if (ctrlRef) {
            let obj = ctrlRef;

            if (Object.hasOwnProperty.call(ctrlRef, 'current')) obj = ctrlRef.current = {};
            obj.moveToLocation = (...args) => controllerRef.current.moveToLocation(...args);
        }

        return () => {
            if (Object.hasOwnProperty.call(ctrlRef, 'current')) {
                ctrlRef.current = undefined;
            } else {
                delete ctrlRef.moveToLocation;
            }
        };
    }, [ctrlRef]);

    React.useEffect(() => {
        onMapLoadedRef.current = onMapLoaded;
    }, [onMapLoaded]);

    React.useLayoutEffect(() => {
        if (loaded && onMapLoadedRef.current) onMapLoadedRef.current();
    }, [loaded]);

    React.useEffect(() => {
        const bounds = mapState.bounds ?? getCurrentArea([mapState.center]).bounds;

        if (!bounds) return;

        const hash = Hash.create(searchState.response.Locations, bounds, enableClusters && cluster);

        if (!Hash.equals(hash, locationHash.current)) {
            const swLat = strToFixed(bounds.sw.lat, 6);
            const neLat = strToFixed(bounds.ne.lat, 6);
            const swLng = strToFixed(bounds.sw.lng, 6);
            const neLng = strToFixed(bounds.ne.lng, 6);
            const foundLocations = searchState.response.Locations.filter((location) => {
                const lat = strToFixed(location.lat, 6);
                const lng = strToFixed(location.lng, 6);

                return lat >= swLat && lat <= neLat && lng >= swLng && lng <= neLng;
            });

            locationHash.current = hash;
            setCulledLocations(
                enableClusters
                    ? Location.createClusters(
                          bounds,
                          mapState.zoom,
                          foundLocations,
                          cluster?.radius,
                          cluster?.extent,
                          cluster?.nodeSize,
                          cluster?.minZoom,
                          cluster?.maxZoom
                      )
                    : foundLocations
            );
        }
    }, [
        searchState.response.Locations,
        mapState.zoom,
        mapState.bounds,
        cluster,
        enableClusters,
        getCurrentArea,
        mapState.center,
    ]);

    React.useLayoutEffect(() => {
        loadingRef.current += 0.5;
        setLoaded(loadingRef.current === 1);

        return () => (loadingRef.current -= 0.5);
    }, []);

    return (
        <div
            className={cx('LocatorMap', className)}
            style={{
                height,
                width,
                position: 'relative',
            }}
            ref={container}
        >
            {enableClusters ? <Script url="https://unpkg.com/kdbush@3.0.0/kdbush.min.js" /> : null}
            {allowResearch ? (
                <UserMessage
                    className="ResearchBar"
                    timestamp={showResearch}
                    timeout={0}
                    message="Dealer.Locater.SearchThisArea.Label"
                    onClick={onResearch}
                    type="success"
                >
                    <i className="fas fa-sync-alt mr-2" />
                </UserMessage>
            ) : null}
            {children}
            <GoogleMap
                ref={googleMapRef}
                onGoogleApiLoaded={onGoogleMapApiLoad}
                bootstrapURLKeys={{ key: apiKey, region, libraries: 'places' }}
                yesIWantToUseGoogleMapApiInternals
                onTilesLoaded={onTilesRendered}
                center={mapState.center}
                zoom={mapState.zoom}
                options={mapOptions}
                onChange={onMapChanged}
                gestureHandling={gestureHandling}
            >
                {culledLocations.map((location, i) => {
                    if (location.cluster_id) {
                        return (
                            <ClusterPinComponent
                                key={`${location.cluster_id}-${location.id}-${i}`}
                                onClick={() =>
                                    controllerRef.current.moveToLocation(
                                        location,
                                        location.getZoom(location.cluster_id)
                                    )
                                }
                                location={location}
                                lat={location.lat}
                                lng={location.lng}
                                updateMap={onUpdate}
                                options={cluster}
                                mapState={mapState}
                                searchState={searchState}
                            />
                        );
                    }
                    return (
                        <PinComponent
                            key={`${location.id}-${i}`}
                            onClick={() => toggleLocation(location.id)}
                            location={location}
                            options={pin}
                            mapState={mapState}
                            lat={location.lat}
                            lng={location.lng}
                            searchState={searchState}
                        >
                            <MapInfoComponent
                                show={mapState.openLocation === location.id}
                                location={location}
                                mapState={mapState}
                                searchState={searchState}
                                onClose={() => closeLocation(location.id)}
                            />
                        </PinComponent>
                    );
                })}
                {searchState.location ? (
                    <PinComponent
                        location={searchState.location}
                        name="You are here"
                        lat={searchState.location.lat}
                        lng={searchState.location.lng}
                        color="green"
                        alt
                    />
                ) : null}
            </GoogleMap>
        </div>
    );
});

// const Coord = px.shape({ lat: px.number, lng: px.number });
// const Bounds = px.shape({ ne: Coord, sw: Coord });
// const Loc = px.shape({ lat: px.number, lng: px.number, id: px.oneOfType([px.string, px.number]) });

// LocatorMap.propTypes = {
//     onUpdate: px.func,
//     onSearchLocation: px.func,
//     searchState: px.shape({
//         input: px.string,
//         location: Loc,
//         response: { Locations: px.arrayOf(Loc) },
//         query: px.shape({ LocationFilter: px.shape({ Distance: px.number }) }),
//     }),
//     mapState: px.shape({
//         openLocation: px.oneOfType([px.string, px.number]),
//         zoom: px.number,
//         bounds: Bounds,
//         center: Coord,
//     }),
//     mapOptions: px.objectOf(px.any),
//     enableClusters: px.bool,
//     cluster: px.objectOf(px.any),
//     pin: px.objectOf(px.any),
//     allowResearch: px.bool,
//     apiKey: px.string,
//     region: px.string,
//     onTilesRendered: px.func,
//     gestureHandling: px.string,
//     ClusterPinComponent: px.elementType,
//     PinComponent: px.elementType,
//     MapInfoComponent: px.elementType,
//     height: px.oneOfType([px.string, px.number]),
//     width: px.oneOfType([px.string, px.number]),
//     className: px.string,
//     onMapLoaded: px.func,
//     children: px.node,
// };
