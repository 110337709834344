const NULLISH = [null, undefined, ''];

export function findAndRemove(arr, predicate) {
    const idx = arr.findIndex(predicate);

    if (idx !== -1) {
        arr.splice(idx, 1);
        return true;
    }
    return false;
}

export default function match(a, b, opts = {}) {
    if (a && typeof a === 'object') {
        if (b && typeof b === 'object') {
            if (Array.isArray(a)) {
                if (Array.isArray(b)) {
                    const b2 = b.slice(0);

                    return (
                        a.length === b.length &&
                        a.reduce((f, x) => f && findAndRemove(b2, (y) => match(x, y, opts)), true)
                    );
                }
                return false;
            }
            if (!Array.isArray(b)) {
                for (const k of Object.keys(a)) {
                    if (!match(a[k], b[k], opts)) {
                        return false;
                    }
                }
                return true;
            }
        }
        return false;
    }
    if (
        (opts.falseIsNull && ((a === false && NULLISH.includes(b)) || (NULLISH.includes(a) && b === false))) ||
        (NULLISH.includes(a) && NULLISH.includes(b)) ||
        a === b
    ) {
        return true;
    }
    return false;
}
