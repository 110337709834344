import * as React from 'react';
import groupBy from 'lodash/groupBy';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { useAction } from 'Common/hooks';
import { customer } from '~features';
import { Loader } from 'Common/components/ui';
import NotificationSubscriptionUnsubscribeButton from './NotificationSubscriptionUnsubscribeButton';
import { Translation } from 'Common/components/localization';

export default function NotificationSubscriptionListing() {
    const [loading, setLoading] = React.useState(false);
    const startLoader = React.useCallback(() => setLoading(true), []);
    const stopLoader = React.useCallback(() => setLoading(false), []);
    const notificationSubscriptions = useSelector(customer.selectors.getNotificationSubscriptionItems);
    const hasNotificationSubscriptions = React.useMemo(() => notificationSubscriptions?.length > 0, [
        notificationSubscriptions,
    ]);

    const fetchList = useAction(customer.actions.getNotificationSubscriptionList, startLoader, stopLoader);

    React.useEffect(() => {
        fetchList();
    }, [fetchList]);

    const groups = React.useMemo(() => Object.entries(groupBy(notificationSubscriptions, 'NotificationTypeLabel')), [
        notificationSubscriptions,
    ]);

    return (
        <div className={cx('notificationsubscriptionblock')}>
            {loading ? <Loader /> : null}
            {hasNotificationSubscriptions ? (
                groups.map(([key, value]) => {
                    return (
                        <div key={key}>
                            <h5>{key}</h5>
                            {value.map((subs) => {
                                return (
                                    <div key={subs?.NotificationUniqueId} className="col-12 block-subscription">
                                        <div className="row row-subscription">
                                            <div className="item-subscription">{subs?.NotificationLabel}</div>
                                            <NotificationSubscriptionUnsubscribeButton
                                                id={subs?.NotificationUniqueId}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })
            ) : (
                <div className="m-2">
                    <p>
                        <Translation id="Commerce.Account.NotificationSubscriptionNoItems" />
                    </p>
                </div>
            )}
        </div>
    );
}
