import { useState, useCallback, useMemo } from 'react';
import { logger } from 'Common/core';

export default function useGooglePay({ options, paymentAmount, currency }) {
    const [error, setError] = useState(null);
    const [token, setToken] = useState(null);

    const paymentRequest = useMemo(
        () => ({
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: [
                {
                    type: 'CARD',
                    parameters: {
                        allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                        allowedCardNetworks: options.EnabledCardNetworks.split(','),
                    },
                    tokenizationSpecification: {
                        type: 'PAYMENT_GATEWAY',
                        parameters: {
                            gateway: 'spreedly',
                            gatewayMerchantId: options.GatewayMerchantId,
                        },
                    },
                },
            ],
            merchantInfo: {
                merchantId: options.MerchantId,
                merchantName: options.MerchantName,
            },
            transactionInfo: {
                totalPriceStatus: 'FINAL',
                totalPriceLabel: 'Total',
                totalPrice: `${paymentAmount}`,
                currencyCode: currency,
                countryCode: 'US',
            },
            // callbackIntents: ['PAYMENT_AUTHORIZATION'],
        }),
        [options, paymentAmount, currency]
    );

    const onLoadPaymentData = useCallback((data) => {
        setToken(data.paymentMethodData.tokenizationData.token);
    }, []);

    const onError = useCallback((e) => {
        logger.error(`GooglePay error:`, e);
        setError(e?.message ?? e);
    }, []);

    const onPaymentAuthorized = useCallback((auth) => {
        setToken(auth.paymentMethodData.tokenizationData.token);
    }, []);

    const logout = useCallback(() => setToken(null), []);

    return { onLoadPaymentData, onPaymentAuthorized, onError, logout, token, error, paymentRequest };
}
