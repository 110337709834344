import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { useConditional } from 'informed';

function evaluate(field) {
    return ({ formApi }) => ({ length: formApi.getValue(field)?.length ?? 0 });
}

export default function CharCounter({ className, field, max = 0, alwaysShow = false }) {
    const { length } = useConditional({
        evaluate: evaluate(field),
        evaluateWhen: [field],
    });

    const invalid = React.useMemo(() => max && length > max, [length, max]);

    return alwaysShow || length ? (
        <small role="contentinfo" className={cx('CharCounter', { invalid }, className)}>
            <span className="length">{length}</span>
            {max ? (
                <>
                    <span className="separator">/</span>
                    <span className="max">{max}</span>
                </>
            ) : null}
        </small>
    ) : null;
}

CharCounter.propTypes = {
    className: px.string,
    field: px.string,
    max: px.number,
    alwaysShow: px.bool,
};
