import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import * as types from 'Common/types';
import { useViewport } from 'Common/hooks';
import { Loader } from 'Common/components/ui';
import CartListing from './CartListing';
import VariantSelector from './VariantSelector';

function stopProp(e) {
    e.stopPropagation();
}

export default function InlineCart({
    className,
    forms = [],
    subtotal = 0,
    loaded = false,
    checkoutPageLink,
    qtyChanges = [],
    onChangeQty,
    onDelete,
    currency,
    onAddItemToCart,
    inputStatusTooltip,
    emptyLabel = 'Commerce.Order.Cart.EmptyCart.Label',
    isEditable = true,
    gtmListValue,
    open = false,
    loading = false,
    cartId,
    onClose,
    cartApiOptions = {
        expand: ['forms'],
        update: ['lineitems', 'promotions'],
    },
    totalQty = 0,
    darkTheme,
}) {
    const viewport = useViewport();

    const [changeVariant, setChangeVariant] = React.useState(null);
    const onEdit = React.useCallback(
        (shipmentId) =>
            isEditable
                ? (lineItem, productInfo) => {
                      setChangeVariant({ shipmentId, lineItem, productInfo });
                  }
                : null,
        [isEditable]
    );

    const cartIsEmpty = React.useMemo(() => {
        return !forms.some((f) => f.Shipments.some((s) => s.LineItems.length + s.FauxLineItems.length));
    }, [forms]);

    const onChangeVariant = React.useCallback(
        async (code, qty, gtmPayload) => {
            if (changeVariant && onAddItemToCart && onDelete) {
                if (!cartIsEmpty) {
                    await onDelete({
                        shipmentId: changeVariant.shipmentId,
                        itemCode: changeVariant.lineItem.Code,
                        cartApiOptions,
                        faux: true,
                    });
                }
                await onAddItemToCart(code, qty, { gtmPayload, cartApiOptions });
                setChangeVariant(null);
            }
        },
        [changeVariant, cartIsEmpty, onDelete, onAddItemToCart, cartApiOptions]
    );

    const onCancel = React.useCallback(() => setChangeVariant(null), []);

    const onBack = React.useCallback(() => {
        if (changeVariant) setChangeVariant(null);
        if (onClose) onClose();
    }, [changeVariant, onClose]);

    return (
        <div
            className={cx('InlineCart', { mobile: viewport.is.mobile, disabled: !open, loading }, className, darkTheme)}
            onClick={onBack}
        >
            <CartListing
                className={cx('drawer', { open }, darkTheme)}
                onEdit={onEdit}
                forms={forms}
                subtotal={subtotal}
                loaded={loaded}
                checkoutPageLink={checkoutPageLink}
                onChangeQty={isEditable ? onChangeQty : null}
                onDelete={isEditable ? onDelete : null}
                qtyChanges={qtyChanges}
                currency={currency}
                emptyLabel={emptyLabel}
                gtmListValue={gtmListValue}
                loading={loading}
                onClose={onBack}
                onClick={stopProp}
                cartId={cartId}
                inputStatusTooltip={inputStatusTooltip}
                includeFaux
                clickable
                totalQty={totalQty}
            />
            <VariantSelector
                className={cx('drawer', { open: !!changeVariant }, darkTheme)}
                changeVariant={changeVariant}
                onClose={onCancel}
                onChangeVariant={onChangeVariant}
                gtmListValue={gtmListValue}
                currency={currency}
                loading={loading}
                onClick={stopProp}
                inputStatusTooltip={inputStatusTooltip}
                totalQty={totalQty}
            />
            {loading ? <Loader className={cx('drawer', { open })} /> : null}
        </div>
    );
}

InlineCart.propTypes = {
    loaded: px.bool,
    className: px.string,
    forms: px.arrayOf(types.CartForm),
    subtotal: px.number,
    onChangeQty: px.func,
    onDelete: px.func,
    currency: px.string,
    qtyChanges: px.arrayOf(px.any),
    onAddItemToCart: px.func,
    isEditable: px.bool,
    emptyLabel: px.string,
    gtmListValue: px.string,
    open: px.bool,
    onClose: px.func,
    checkoutPageLink: px.string,
    inputStatusTooltip: px.oneOfType([px.string, px.bool]),
    loading: px.bool,
    cartId: px.node,
    cartApiOptions: types.cartApiOptions,
    darkTheme: px.string,
    totalQty: px.number,
};
