import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { CreditCard } from 'Common/utils';
import { Icon } from 'Common/components/ui';
import { ExpDate, CardNumber, CardCVV } from 'Common/components/localization';

export default function PaymentDisplay({
    className,
    issuer,
    name,
    type,
    number,
    year,
    month,
    placeholder,
    separator,
    lastFour = false,
    cvv,
}) {
    const icon = React.useMemo(() => CreditCard.getIcon(type === 'CreditCard' ? issuer ?? type : type), [issuer, type]);

    return type === 'CreditCard' ? (
        <div className={cx('PaymentDisplay', type, className)}>
            <div className="icon">
                <Icon icon={icon} />
            </div>
            {placeholder ? (
                <div className="details placeholder">
                    <span className="name placeholder">XXXXX XXXXXXXX</span>
                    <span className="number placeholder">
                        <CardNumber separator={separator} issuer={issuer} mask="X" lastFour={lastFour} />
                    </span>
                    <span className="exp placeholder">
                        <ExpDate mask="X" forceMask />
                    </span>
                    {type === 'CreditCard' ? (
                        <span className="cvv placeholder">
                            <CardCVV mask="X" issuer={issuer} />
                        </span>
                    ) : null}
                </div>
            ) : (
                <div className="details">
                    {name ? <span className="name">{name}</span> : null}
                    {number ? (
                        <span className="number">
                            <CardNumber number={number} separator={separator} issuer={issuer} lastFour={lastFour} />
                        </span>
                    ) : null}
                    {month && year ? (
                        <span className="exp">
                            <ExpDate month={month} year={year} mask="X" />
                        </span>
                    ) : null}
                    {type === 'CreditCard' ? (
                        <span className="cvv">
                            <CardCVV mask="X" cvv={cvv} issuer={issuer} />
                        </span>
                    ) : null}
                </div>
            )}
        </div>
    ) : (
        <div className={cx('PaymentDisplay', type, className)}>
            <div className="icon">
                <Icon icon={icon} />
            </div>
        </div>
    );
}

PaymentDisplay.propTypes = {
    className: px.string,
    issuer: px.string,
    type: px.string,
    name: px.string,
    number: px.string,
    year: px.oneOfType([px.string, px.number]),
    month: px.oneOfType([px.string, px.number]),
    placeholder: px.bool,
    separator: px.string,
    lastFour: px.bool,
    cvv: px.string,
};
